import { plainToClass } from 'class-transformer';
import { injectable, Inject } from 'inversify-props';
import { ClassType } from 'class-transformer/ClassTransformer';
import ApiService, { ApiServiceS } from '@/modules/common/services/api.service';
import CarAlertModel from '../../alerts/models/car.alert.model';
import CarsSharedService, { CarsSharedServiceS } from '../../cars-shared.service';

export const NotificationsApiServiceS = Symbol.for('NotificationsApiServiceS');

@injectable(NotificationsApiServiceS as unknown as string)
export default class NotificationsApiService {
    @Inject(ApiServiceS) private apiService!: ApiService;
    @Inject(CarsSharedServiceS) protected carsSharedService!: CarsSharedService;
    private url = 'cr-insight';

    async getNotifications(params: Record<any, any>) {
        const query = {
            ...params,
            chain_id: this.carsSharedService.currentChainId,
        };
        const res = await this.apiService.get(`${this.url}/get-alert-message-by-user`, query);

        if (!res || !res.data || !res.data) {
            return null;
        }

        const alertsDocument = plainToClass(CarAlertModel, <CarAlertModel[]> res.data, { excludeExtraneousValues: true });

        return alertsDocument;
    }
}
