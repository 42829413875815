import { Inject, injectable } from 'inversify-props';
import LocationAvailabilityDocumentModel from '@/modules/cars/modules/location-availability/models/location-availability-document.model';
import StoreFacade, { StoreFacadeS } from '@/modules/common/services/store-facade';
import DocumentFiltersService, { DocumentFiltersServiceS } from '@/modules/document-filters/document-filters.service';
import LocationAvailabilityApiService, { LocationAvailabilityApiServiceS }
    from '@/modules/cars/modules/location-availability/location-availability-api.service';
import HelperService, { HelperServiceS } from '@/modules/common/services/helper.service';
import LocationAvailabilityFiltersService, { LocationAvailabilityFiltersServiceS }
    from '@/modules/cars/modules/location-availability/location-availability-filters.service';
import _ from 'lodash';
import LocationAvailabilityStore from './store/location-availability.store';

export const LocationAvailabilityServiceS = Symbol.for('LocationAvailabilityServiceS');
@injectable(LocationAvailabilityServiceS as unknown as string)
export default class LocationAvailabilityService {
    @Inject(StoreFacadeS) private storeFacade!: StoreFacade;
    @Inject(LocationAvailabilityApiServiceS) private locationAvailabilityApiService!: LocationAvailabilityApiService;
    @Inject(LocationAvailabilityFiltersServiceS) private lAvailabilityFiltersService!: LocationAvailabilityFiltersService;
    @Inject(DocumentFiltersServiceS) private documentFiltersService!: DocumentFiltersService;
    @Inject(HelperServiceS) private helperService!: HelperService;

    readonly storeState: LocationAvailabilityStore = this.storeFacade.getState('LocationAvailabilityStore');

    constructor() {
        this.storeFacade.watch(() => [
            this.storeState.filtersReady,
        ], this.init.bind(this));
    }

    init() {
        // Note: changes of country and pick-up locations are watched in LocationAvailabilityFiltersService because we need to update filters first.
        // Once filters are updated, we change store's allPickUpCityCodes which are watched below.
        this.storeFacade.watch(() => [
            this.documentFiltersService.month,
            // allPickUpCityCodes are changed on country or pick-up location change after POS/locations were updated
            this.storeState.settings.allPickUpCityCodes,
            this.storeState.settings.pos,
            this.storeState.filtersReady,
        ], this.resetLoading.bind(this));

        this.storeFacade.watch(() => [
            this.storeState.settings.lor,
        ], this.calculateClosedAndUnAvailableDays.bind(this));
    }

    resetLoading() {
        if (this.storeState.filtersReady && !this.storeState.loading.isLoading()) {
            this.storeState.loading.reset();
        }
    }

    async loadData(): Promise<boolean> {
        const { settings } = this.documentFiltersService.storeState;
        const locationAvailabilitySettings = this.storeState.settings;
        const { pos, allPickUpCityCodes } = locationAvailabilitySettings;
        if (!pos || !allPickUpCityCodes || !allPickUpCityCodes.length) {
            return false;
        }
        const apiData = { ...locationAvailabilitySettings };
        const data = await this.locationAvailabilityApiService.getNotAvailableDocs(settings, apiData);

        const documents = {} as {
            [locationId: string] : LocationAvailabilityDocumentModel
        };

        data.forEach((location: LocationAvailabilityDocumentModel) => {
            documents[location.locationCode] = location;
        });

        this.storeState.documents = documents;
        this.calculateClosedAndUnAvailableDays();

        return true;
    }

    get documents() {
        if (this.storeState.filtersReady) {
            this.helperService.dynamicLoading(this.storeState.loading, this.loadData.bind(this));
        }
        return this.storeState.documents;
    }

    get closedDays() {
        return this.storeState.closedDays;
    }

    get unAvailableDays() {
        return this.storeState.unAvailableDays;
    }

    calculateClosedAndUnAvailableDays() {
        const closedDays = { } as {
            [location: string]: {
                [provider: string]: number
            }
        };

        const unAvailableDays = { } as {
            [location: string]: {
                [provider: string]: number
            }
        };

        const { providersList } = this.lAvailabilityFiltersService;
        const documents = this.documents || {};

        Object.keys(documents).forEach(locationId => {
            const pickupDays = documents[locationId].pickupDates;
            closedDays[locationId] = {} as any;
            unAvailableDays[locationId] = {} as any;
            if (pickupDays) {
                Object.keys(pickupDays).forEach(day => {
                    providersList.forEach(provider => {
                        if (!closedDays[locationId][provider]) {
                            closedDays[locationId][provider] = 0;
                        }
                        if (!unAvailableDays[locationId][provider]) {
                            unAvailableDays[locationId][provider] = 0;
                        }
                        const closedDaysStatus = this.getLocationsAvailability(locationId, provider, day);
                        if (closedDaysStatus === true) {
                            closedDays[locationId][provider] += 1;
                        }
                        if (closedDaysStatus === null) {
                            unAvailableDays[locationId][provider] += 1;
                        }
                    });
                });
            }
        });

        this.storeState.closedDays = closedDays;
        this.storeState.unAvailableDays = unAvailableDays;
    }

    getLocationsAvailability(locationId: string, provider: string, day: string) {
        const { lor } = this.storeState.settings;
        const noDataStatus = null;
        const key = `${locationId}.pickupDates.${day}.${provider}.${lor}`;
        return _.get(this.documents, key, noDataStatus) as boolean | null;
    }
}
