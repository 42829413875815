import { Expose, Transform } from 'class-transformer';

export default class CarsHolidaysCountriesModel {
    @Expose()
    @Transform((_, plain) => plain.chain_id)
    chainId!: string;

    @Expose()
    @Transform((_, plain) => plain.countries)
    countries!: string[];
}
