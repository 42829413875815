import { injectable } from 'inversify-props';
import CarsRateAlertService from './car.rate-alert.service';
import { CarRateAlertType } from './modules/settings/enums/alerts.enum';

export const CarsBrandAlertServiceS = Symbol.for('CarsBrandAlertServiceS');

@injectable(CarsBrandAlertServiceS as unknown as string)
export default class CarsBrandAlertService extends CarsRateAlertService {
    readonly storeState: any = this.storeFacade.getState('CarsBrandAlertStore');
    protected alertType = CarRateAlertType.BRAND_DIFF;
}
