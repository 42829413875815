import LoadingModel from '@/modules/common/models/loading.model';
import { Module, VuexModule } from 'vuex-module-decorators';
import CarsCompSetModel from '../models/cars-comp-set.model';
import { CompsetsLogChangeItemModel, CompsetsLogDeletedItemModel, CompsetsLogItemModel } from '../modules/settings/models';

@Module
export default class CarsCompSetStore extends VuexModule {
    document: CarsCompSetModel[] = [];
    previewDocuments: CarsCompSetModel | null = null;
    loading: LoadingModel = new LoadingModel();
    saveRequestLoading = new LoadingModel();
    settings = {
        vendorsList: {},
    };
    historyLogs: {
        logs: CompsetsLogItemModel[] | null,
        version: number | null,
        changes: CompsetsLogChangeItemModel[] | null,
        deletedItems: CompsetsLogDeletedItemModel[] | null,
        loading: LoadingModel,
        showChangesOnly: boolean;
    } = {
        logs: null,
        version: null,
        changes: null,
        deletedItems: null,
        loading: new LoadingModel(),
        showChangesOnly: false,
    };
}
