import { get } from 'lodash';
import CarsExtraExpensesModel from '@/modules/cars/models/cars-extra-expenses.model';
import { Expose, plainToClass, Transform } from 'class-transformer';
import {
    IsString, IsOptional, IsNumber, IsArray,
} from 'class-validator';
import { COUNTRIES_ANY } from '../constants/car-filter-types.constant';
import type { IRentalCarVendorsPerCountryDocument } from './cars-comp-set.model';

export type LocationType = {
    locationId: string,
    locationName: string,
    geoLocation: number[]
}[] | null;

const transformLocations = (key: string) => (_: any, plain: any) => {
    const locations = get(plain, key, plain.locations);
    if (!locations) {
        return null;
    }

    return locations.map((location: {
        // eslint-disable-next-line camelcase
        fn_location_id: string,
        // eslint-disable-next-line camelcase
        location_name: string,
        // eslint-disable-next-line camelcase
        geo_location: number[],
    }) => ({
        locationId: location.fn_location_id,
        locationName: location.location_name,
        geoLocation: location.geo_location,
    }));
};

interface IPath {
    [dataSource: string]: {
        [pos: string]: {
            [lok: string]: boolean,
        }
    }
}

export interface ITheme {
    logo: string | null;
    primaryColor: string | null;
    secondaryColor: string | null;
    icons: string | null;
}

interface ICluster {
    [country: string]: {
        [cluster: string]: string[];
    };
}

interface IFeatures {
    clusterMode: boolean;
}

interface ICountry {
    name: string;
    code: string;
}

export interface ICarProvider {
    displayName: string;
    groupedDocumentName: string;
    providerName: string;
    scanMethod: string;
}

export default class CarsFiltersModel {
    @Expose()
    @Transform((_, plain) => plainToClass(CarsExtraExpensesModel, <CarsExtraExpensesModel[]> plain.extraExpenses || []))
    @IsOptional()
    extraExpenses: CarsExtraExpensesModel[] = [];

    @Expose()
    @Transform((_, plain) => plain.allowed_pos_code_locations)
    @IsOptional()
    allowedPosCodeLocations: {
        [pos: string]: {
            [fnLocationId: string]: boolean
        }
    } = {};

    @Expose()
    @Transform((_, plain) => plain.pick_up_cities)
    @IsString({ each: true })
    @IsOptional()
    pickUpCities: string[] | null = null;

    @Expose()
    @Transform((_, plain) => plain.data_sources)
    @IsString({ each: true })
    @IsOptional()
    dataSources: string[] | null = null;

    @Expose()
    @Transform((_, plain) => (plain.lor && Array.isArray(plain.lor) ? plain.lor.map(Number).sort((a: number, b: number) => a - b) : []))
    @IsNumber({}, { each: true })
    @IsOptional()
    lor: number[] | null = null;

    @Expose()
    @IsString({ each: true })
    @IsOptional()
    pos: string[] | null = null;

    @Expose()
    @Transform((_, plain) => plain.pos_codes)
    @IsString({ each: true })
    @IsOptional()
    posCodes: string[] | null = null;

    @Expose()
    @Transform(transformLocations('van_locations'))
    @IsArray({ each: true })
    @IsOptional()
    vanLocations: LocationType = null;

    @Expose()
    @IsArray({ each: true })
    @Transform(transformLocations('car_locations'))
    @IsOptional()
    carLocations: LocationType = null;

    @Expose()
    @Transform(transformLocations('locations'))
    @IsArray()
    locations: LocationType = null;

    @Expose()
    @Transform((_, plain) => plain.country_locations)
    countries: {
        [country: string]: string[],
    } | null = null;

    @Expose()
    @Transform((_, plain) => (
        plain.routes.map((route: {
            pos: string[],
            // eslint-disable-next-line camelcase
            fn_pick_up_location_code: string,
        }) => ({
            pos: route.pos,
            pickUpLocationId: route.fn_pick_up_location_code,
        }))
    ))
    routes: {
        pos: string[],
        pickUpLocationId: string,
    }[] | null = null;

    @Expose()
    @Transform((_, plain) => (
        plain.grouped_availability.map((group: {
            // eslint-disable-next-line no-underscore-dangle
            _id: string,
            path: IPath
        }) => ({
            // eslint-disable-next-line no-underscore-dangle
            id: group._id,
            path: group.path,
        }))
    ))
    availability: {
        path: IPath
        id: string
    }[] | null = null;

    @Expose()
    @Transform((_, plain) => (
        plain.grouped_availability_vans.map((group: {
            // eslint-disable-next-line no-underscore-dangle
            _id: string,
            path: IPath
        }) => ({
            // eslint-disable-next-line no-underscore-dangle
            id: group._id,
            path: group.path,
        }))
    ))
    availabilityVans: {
        path: IPath
        id: string
    }[] | null = null;

    @Expose()
    @Transform((_, plain) => plain.mileage)
    mileage: string[] | null = null;

    @Expose()
    @Transform((_, plain) => plain.transmission)
    transmission: string[] | null = null;

    @Expose()
    doors: string[] | null = null;

    @Expose()
    paymentTerms: string[] | null = null;

    @Expose()
    @Transform((_, plain) => plain.car_class)
    carClass: string[] | null = null;

    @Expose()
    @Transform((_, plain) => {
        if (Object.keys(plain.theme).length === 0) {
            return null;
        }
        return plain.theme;
    })
    theme: ITheme | null = null;

    @Expose()
    allowedBrands: string[] | null = null;

    @Expose()
    @Transform((_, plain) => {
        const providers = plain.data_sources.filter((item: string) => item !== 'Brand');
        return [...providers, ...plain.allowedBrands];
    })
    providers: string[] | null = null;

    competitors: string[] | null = null;

    @Expose()
    @Transform((_, plain) => plain.vehicle_types.sort())
    @IsString({ each: true })
    vehicleTypes: string[] | null = null;

    @Expose()
    @Transform((_, plain) => {
        const data: ICluster = plain.cluster || { };

        data[COUNTRIES_ANY] = { };

        Object.keys(data || { }).forEach((country: string) => {
            Object.keys(data[country] || { }).forEach((cluster: string) => {
                if (data[country][cluster].length === 0) {
                    delete data[country][cluster];
                }
            });
        });

        return data;
    })
    clusters: ICluster | null = null;

    @Expose()
    @Transform((_, plain) => {
        const { features } = plain;
        if (!features) {
            return { clusterMode: true };
        }
        return features;
    })
    features: IFeatures = { clusterMode: false };

    @Expose()
    @IsString()
    @Transform((_, plain) => {
        if (plain.features) {
            return plain.features.chainCode;
        }
        return null;
    })
    chainCode: string | null = null;

    @Expose()
    @IsString()
    @Transform((_, plain) => {
        if (plain.features) {
            return plain.features.chainMode || 'Brand';
        }
        return null;
    })
    chainMode: string | null = null;

    @Expose()
    @IsString()
    @Transform((_, plain) => {
        if (plain.features) {
            return plain.features.availability;
        }
        return true;
    })
    isAvailability: boolean | null = null;

    @Expose()
    @Transform((_, plain) => {
        const { countries } = plain;
        return Object.keys(countries).map(code => ({ code, name: countries[code] }));
    })
    countryCodes: ICountry[] | null = null;

    @Expose()
    @Transform((_, plain) => plain.allowedLORs || {})
    allowedLORs: Record<string, number[]> = {};

    @Expose()
    @Transform((_, plain) => plain.defaultLocationsPOS || {})
    defaultLocationsPOS: Record<string, string> = {};

    @Expose()
    @Transform((_, plain) => plain.posProviderHidden || {})
    posProviderHidden: Record<string, string[]> = {};

    @Expose()
    @Transform((_, plain) => {
        if (!plain.car_providers) {
            return [];
        }

        return plain.car_providers.map((carProvider: {
            // eslint-disable-next-line camelcase
            display_name: string,
            // eslint-disable-next-line camelcase
            grouped_document_name: string,
            // eslint-disable-next-line camelcase
            provider_name: string,
            // eslint-disable-next-line camelcase
            scan_method: string,
        }) => ({
            displayName: carProvider.display_name,
            groupedDocumentName: carProvider.grouped_document_name,
            providerName: carProvider.provider_name,
            scanMethod: carProvider.scan_method,
        }));
    })
    @IsArray()
    carProviders: ICarProvider[] = [];

    @Expose()
    brands: string[] | null = null;

    fuelTypes: string[] = [];

    @Expose()
    @Transform((_, plain) => plain.allowedVendorsPerCountry || {})
    allowedVendorsPerCountry: IRentalCarVendorsPerCountryDocument = {};

    @Expose()
    @Transform((_, plain) => plain.aggregateAllowedVendorsPerCountry || {})
    aggregateAllowedVendorsPerCountry: IRentalCarVendorsPerCountryDocument = {};

    @Expose()
    @Transform((_, plain) => plain.allowedParityPoss)
    @IsString({ each: true })
    @IsOptional()
    allowedParityPoss: string[] | null = null;

    @Expose()
    @Transform((_, plain) => plain.allowedSpotchecksDataSources)
    @IsString({ each: true })
    @IsOptional()
    spotchecksDataSources: string[] | null = null;

    @Expose()
    @Transform((_, plain) => plain.allowedSpotchecksUI)
    @IsString({ each: true })
    @IsOptional()
    allowedSpotchecksUI: boolean = false;
}
