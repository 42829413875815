import { injectable } from 'inversify-props';
import CarsRateAlertService from './car.rate-alert.service';
import { CarRateAlertType } from './modules/settings/enums/alerts.enum';

export const CarsTrendAlertServiceS = Symbol.for('CarsTrendAlertServiceS');

@injectable(CarsTrendAlertServiceS as unknown as string)
export default class CarsTrendAlertService extends CarsRateAlertService {
    readonly storeState: any = this.storeFacade.getState('CarsTrendAlertStore');
    protected alertType = CarRateAlertType.TREND_DIFF;
}
