import { validate, ValidationError } from 'class-validator';
import { Inject, injectable } from 'inversify-props';
import * as _ from 'lodash';
import { $enum } from 'ts-enum-util';
import BadRequestException from '@/modules/common/modules/exception-handler/exceptions/bad-request.exception';
import HelperService, { HelperServiceS } from '@/modules/common/services/helper.service';
import Month from '@/modules/common/types/month.type';
import Year from '@/modules/common/types/year.type';
import CompsetsService, { CompsetsServiceS } from '@/modules/compsets/compsets.service';
import DocumentFiltersService, { DocumentFiltersServiceS } from '@/modules/document-filters/document-filters.service';
import EVENT_STATUS_SETTINGS from '@/modules/events/constants/event-statuses.constant';
import EVENT_TYPE_SETTINGS from '@/modules/events/constants/event-types-settings.constant';
import EventsApiService, { EventsApiServiceS } from '@/modules/events/events-api.service';
import EventsFilterService, { EventsFilterServiceS } from '@/modules/events/events-filter.service';
import IDayEvent from '@/modules/events/interfaces/day-event.interface';
import EventsItemModel from '@/modules/events/models/events-item.model';
import EventsModel from '@/modules/events/models/events.model';
import EventsStore from '@/modules/events/store/events.store';
import IMonthEventsParams from '@/modules/events/interfaces/month-events-params';
import IDayEventsParams from '@/modules/events/interfaces/day-events-params.interface';
import IEventsFilter from '@/modules/events/interfaces/events-filter.interface';
import UserService, { UserServiceS } from '@/modules/user/user.service';
import Vue from 'vue';
import * as moment from 'moment';
import StoreFacade, { StoreFacadeS } from '../common/services/store-facade';
import ClusterService, { ClusterServiceS } from '../cluster/cluster.service';
import CarsSharedService, { CarsSharedServiceS } from '../cars/cars-shared.service';
import { CarUtils } from '../cars/utils/car.util';

export const EventsManagerServiceS = Symbol.for('EventsManagerServiceS');
@injectable(EventsManagerServiceS as unknown as string)
export default class EventsManagerService {
    @Inject(DocumentFiltersServiceS) private documentFiltersService!: DocumentFiltersService;
    @Inject(EventsApiServiceS) private eventsApiService!: EventsApiService;
    @Inject(CompsetsServiceS) private compsetsService!: CompsetsService;
    @Inject(ClusterServiceS) private clusterService!: ClusterService;
    @Inject(UserServiceS) private userService!: UserService;
    @Inject(StoreFacadeS) private storeFacade!: StoreFacade;
    @Inject(HelperServiceS) private helperService!: HelperService;
    @Inject(EventsFilterServiceS) private eventsFilterService!: EventsFilterService;
    @Inject(CarsSharedServiceS) private carsSharedService!: CarsSharedService;

    readonly storeState: EventsStore = this.storeFacade.getState('EventsStore');

    private memoizers: { [key: string]: Function } = {};

    constructor() {
        this.storeFacade.watch(() => [
            this.documentFiltersService.storeState.settings.compsetId,
            this.documentFiltersService.storeState.settings.month,
            this.documentFiltersService.storeState.settings.year,
            // @ts-ignore
        ], ((newValue, oldValue) => {
            const [newCompsetId, newMonth, newYear] = newValue;
            const [oldCompsetId, oldMonth, oldYear] = oldValue;

            if (newCompsetId === oldCompsetId
                && newMonth === oldMonth
                && newYear === oldYear) {
                return {};
            }

            return this.storeState.loading.reset.call(this.storeState.loading);
        }));

        if (this.userService.isClusterUser || this.userService.isChainUser) {
            this.storeFacade.watch(() => [
                this.userService.viewAs,
                this.userService.currentHotelId,
                // @ts-ignore
            ], ((newValue, oldValue) => {
                const [oldViewAs, oldCurrentHotelId] = oldValue;
                const [newViewAs, newCurrentHotelId] = newValue;

                if (oldCurrentHotelId === newCurrentHotelId
                    && oldViewAs === newViewAs) {
                    return {};
                }
                return this.storeState.loading.reset.call(this.storeState.loading);
            }));
        }
    }

    get isLoading() {
        return this.storeState.loading.isLoading();
    }

    protected resetEvents() {
        this.storeState.cacheEvents = {};
        this.storeState.cacheLoaded = {};

        Object.values(this.memoizers).forEach((func: any) => {
            func.cache.clear();
        });
        const data = this.getEventsByMonth({ }, { }, { updateFilters: true });
        this.storeState.forceUpdate = true;
    }

    protected events(params: IMonthEventsParams, preload: boolean = true): EventsItemModel {
        const { month, year, country } = params as Required<IMonthEventsParams>;
        const cacheKey = `${year}-${month}.${country}`;

        if (!this.storeState.cacheEvents[cacheKey]) {
            Vue.set(this.storeState.cacheEvents, cacheKey, new EventsItemModel());

            // We can load multiple months simultaneously, hence don't use default block by model like this:
            // this.helperService.dynamicLoading(this.storeState.loading, this.loadData.bind(this, month, year, country));
            // Loading model is used for main month only, not for preloaded months
            if (preload) {
                this.storeState.loading.start();
            }
            this.loadData.call(this, month, year, country).then(() => {
                if (preload) {
                    this.storeState.loading.finish();

                    // Preload prev/next months in async, don't wait for them.
                    let monthNext = month + 1;
                    let yearNext = year;
                    if (monthNext === 12) {
                        monthNext = 0;
                        yearNext++;
                    }

                    let monthPrev = month - 1;
                    let yearPrev = year;
                    if (monthPrev === -1) {
                        monthPrev = 11;
                        yearPrev--;
                    }

                    const cacheKeyNext = `${yearNext}-${monthNext}.${country}`;
                    const cacheKeyPrev = `${yearPrev}-${monthPrev}.${country}`;

                    if (!this.storeState.cacheEvents[cacheKeyNext]) {
                        this.events({ month: monthNext as Month, year: yearNext as Year, country }, false);
                    }
                    if (!this.storeState.cacheEvents[cacheKeyPrev]) {
                        this.events({ month: monthPrev as Month, year: yearPrev as Year, country }, false);
                    }
                }
            }).catch(() => {
                // On error: stop next loading
                // Mark this day as loaded.
                Vue.set(this.storeState.cacheLoaded, cacheKey, Date.now());
            });
        }

        // Additional load of all countries
        if (!country && this.storeState.cacheEvents[cacheKey].reloadAllCountries) {
            this.storeState.cacheEvents[cacheKey].reloadAllCountries = false;
            if (preload) {
                this.storeState.loading.start();
            }
            this.eventsApiService.getHolidaysEvents(month, year, undefined).then(res => {
                this.storeState.cacheEvents[cacheKey].holiday = res || [];
                // refresh cache key to reload data
                Vue.set(this.storeState.cacheLoaded, cacheKey, Date.now());
                if (preload) {
                    this.storeState.loading.finish();

                    // Populate filter options for the currently requested month only.
                }
            });
        }

        return this.storeState.cacheEvents[cacheKey];
    }

    protected async loadData(month: Month, year: Year, country: string): Promise<boolean> {
        const { isViewAsHotel, isChainOrClusterUser, isHotelUser } = this.userService;
        const { loading: posLoading } = this.clusterService.storeState.pos;

        if ((isViewAsHotel || (!isChainOrClusterUser && isHotelUser)) && !this.compsetsService.currentCompset) {
            return false;
        }

        if (isChainOrClusterUser && !isViewAsHotel && !this.eventsFilterService.defaultCountryCodes?.length && !posLoading.finishDate) {
            await posLoading.whenLoadingFinished();
        }

        const cacheKey = `${year}-${month}.${country}`;
        const event = await this.loadMonthEvents(month, year, country);
        const { countries } = this.eventsFilterService;
        event.holiday.map(holiday => {
            if (countries) {
                // eslint-disable-next-line no-restricted-syntax
                for (const code of Object.keys(countries)) {
                    if (code.toLowerCase() === holiday.country?.toLowerCase()) {
                        // eslint-disable-next-line no-param-reassign
                        holiday.country = countries[code];
                    }
                }
            }
            return holiday;
        });
        this.storeState.cacheEvents[cacheKey] = event;

        // Mark this month as loaded.
        Vue.set(this.storeState.cacheLoaded, cacheKey, Date.now());

        return true;
    }

    protected async loadMonthEvents(month: Month, year: Year, country: string): Promise<EventsItemModel> {
        const monthEvents = new EventsItemModel();
        const [holidayEvents, suggestedEvents, myEvents, chainEvents] = await Promise.all([
            this.loadHolidayEvents(month, year, country),
            this.loadSuggestedEvents(month, year),
            this.loadMyEvents(month, year),
            this.loadChainEvents(month, year),
        ]);

        monthEvents.holiday = holidayEvents || [];
        monthEvents.suggested = suggestedEvents || [];
        monthEvents.my = myEvents || [];
        monthEvents.chain = chainEvents || [];

        return monthEvents;
    }

    protected async loadHolidayEvents(month: Month, year: Year, country?: string) {
        const isCustomCarHolidaysEnable = this.carsSharedService.currentChain?.enableCustomHolidays;
        const cacheKey = `${year}-${month}.${country || ''}`;
        const chainId = this.carsSharedService.currentChain?.chainId || '';
        if (this.storeState.cacheLoaded[cacheKey]) {
            return this.storeState.cacheEvents[cacheKey].holiday;
        }
        // We could load non-country month before, use the data from all countries, but if they were loaded.
        if (country) {
            const cacheKeyAll = `${year}-${month}.`;
            const countryCodes = country.split(',');
            if (this.storeState.cacheLoaded[cacheKeyAll] && !this.storeState.cacheEvents[cacheKeyAll].reloadAllCountries) {
                return this.storeState.cacheEvents[cacheKeyAll].holiday.filter(event => countryCodes.includes(event.countryCode?.toUpperCase() || ''));
            }
        }
        if (isCustomCarHolidaysEnable) {
            const allCountries = country ? country.split(',') : (this.carsSharedService.carsFilterStoreState.settings.countryCodes?.map(country => country.code) || []);
            const countries = allCountries.filter(country => !this.storeState.carHolidaysCountries.includes(country));
            const chainHolidaysEvents = await this.eventsApiService.getChainHolidaysEvents(chainId, month, year, country ? country.split(',') : undefined);
            if (countries) {
                const ciEvents = await this.eventsApiService.getHolidaysEvents(month, year, countries) || [];
                return [...chainHolidaysEvents, ...ciEvents];
            }
            if (chainHolidaysEvents.length) {
                return chainHolidaysEvents;
            }
        }
        return this.eventsApiService.getHolidaysEvents(month, year, country ? country.split(',') : undefined);
    }

    protected async loadSuggestedEvents(month: Month, year: Year) {
        if (!this.userService.isViewAsCluster && !this.userService.isViewAsChain) {
            const cacheKey = `${year}-${month}.`;
            if (this.storeState.cacheLoaded[cacheKey]) {
                return this.storeState.cacheEvents[cacheKey].suggested;
            }
            return this.userService.isCarUser ? this.eventsApiService.getSuggestedCarEvents(month, year) : this.eventsApiService.getSuggestedHotelEvents(month, year);
        }
        return null;
    }

    protected async loadMyEvents(month: Month, year: Year) {
        if (!this.userService.isViewAsCluster && !this.userService.isViewAsChain) {
            const cacheKey = `${year}-${month}.`;
            if (this.storeState.cacheLoaded[cacheKey]) {
                return this.storeState.cacheEvents[cacheKey].my;
            }
            return this.userService.isCarUser ? this.eventsApiService.getMyCarEvents(month, year) : this.eventsApiService.getMyHotelEvents(month, year);
        }
        return null;
    }

    protected async loadChainEvents(month: Month, year: Year) {
        if (this.userService.isViewAsCluster || this.userService.isViewAsChain) {
            const cacheKey = `${year}-${month}.`;
            if (this.storeState.cacheLoaded[cacheKey]) {
                return this.storeState.cacheEvents[cacheKey].chain;
            }
            return this.eventsApiService.getChainEvents(month, year);
        }
        return null;
    }

    protected getMyEventsByMonth(params: IMonthEventsParams, filter: IEventsFilter | undefined = {}): EventsModel[] {
        this.memoizers.getMyEventsByMonth = this.memoizers.getMyEventsByMonth || _.memoize((p: IMonthEventsParams, f: IEventsFilter | undefined) => {
            let myEvents = this.events(p).my;

            if (f) {
                const { types, status } = f as IEventsFilter;
                if (status === EVENT_STATUS_SETTINGS.SUGGESTED) {
                    return [];
                }

                myEvents = myEvents.filter((event: EventsModel) => types!.some(type => type === event.type));
                // Needs to show old events with unsupported types like 'other'
                if (types!.some(type => type === EVENT_TYPE_SETTINGS.OTHER)) {
                    const myOldEvents = this.events(p).my
                        .filter((event: EventsModel) => !$enum(EVENT_TYPE_SETTINGS).getValues().some(t => t === event.type));
                    myEvents = [...myEvents, ...myOldEvents];
                }
            }
            return myEvents;
        }, (...args: any) => JSON.stringify(args.concat(this.storeState.cacheLoaded[`${args[0].year}-${args[0].month}.${args[0].country}`] || 0)));
        return this.memoizers.getMyEventsByMonth(params, filter);
    }

    protected getSuggestedEventsByMonth(params: IMonthEventsParams, filter: IEventsFilter | undefined = {}): EventsModel[] {
        this.memoizers.getSuggestedEventsByMonth = this.memoizers.getSuggestedEventsByMonth || _.memoize((p: IMonthEventsParams, f: IEventsFilter | undefined) => {
            let suggestedEvents = this.events(p).suggested;

            if (f) {
                const { types, status } = f;
                if (status === EVENT_STATUS_SETTINGS.APPROVED) {
                    return [];
                }
                suggestedEvents = suggestedEvents.filter((event: EventsModel) => types!.some(type => type === event.type));
            }
            return suggestedEvents;
        }, (...args: any) => JSON.stringify(args.concat(this.storeState.cacheLoaded[`${args[0].year}-${args[0].month}.${args[0].country}`] || 0)));
        return this.memoizers.getSuggestedEventsByMonth(params, filter);
    }

    protected getChainEventsByMonth(params: IMonthEventsParams, filter: IEventsFilter | undefined = {}): EventsModel[] {
        this.memoizers.getChainEventsByMonth = this.memoizers.getChainEventsByMonth || _.memoize((p: IMonthEventsParams, f: IEventsFilter | undefined) => {
            let chainEvents = this.events(p).chain;

            if (f) {
                const { types } = f;
                chainEvents = chainEvents.filter((event: EventsModel) => types!.some(type => type === event.type));
                // Needs to show old events with unsupported types like 'other'
                if (types!.some(type => type === EVENT_TYPE_SETTINGS.OTHER)) {
                    const chainOldEvents = this.events(p).chain
                        .filter((event: EventsModel) => !$enum(EVENT_TYPE_SETTINGS).getValues().some(t => t === event.type));
                    chainEvents = [...chainEvents, ...chainOldEvents];
                }
            }
            return chainEvents;
        }, (...args: any) => JSON.stringify(args.concat(this.storeState.cacheLoaded[`${args[0].year}-${args[0].month}.${args[0].country}`] || 0)));
        return this.memoizers.getChainEventsByMonth(params, filter);
    }

    /**
     * Note: this methods can be called directly, we need to apply default filters values.
     */
    getHolidayEventsByMonth(params: IMonthEventsParams, filter: IEventsFilter | undefined = {})
        : { holiday: EventsModel[], countriesList: { [countryCode: string]: string }, countries: string[]} {
        const paramsReq = this.addDefaultMonthParams(params);
        const filterReq = filter ? this.addDefaultFilterValues(filter) : undefined;

        this.memoizers.getHolidayEventsByMonth = this.memoizers.getHolidayEventsByMonth || _.memoize((p: Required<IMonthEventsParams>, f: Required<IEventsFilter> | undefined) => {
            let holidayEvents = this.events(p).holiday;
            const countriesData = this.eventsFilterService.getCountries(holidayEvents);
            if (f) {
                const { countries } = f;
                holidayEvents = holidayEvents.filter((event: EventsModel) => countries.includes((event.countryCode || '').toUpperCase()));
            }
            return { holiday: holidayEvents, countriesList: countriesData.countriesList, countries: countriesData.countries };
        }, (...args: any) => JSON.stringify(args.concat(this.storeState.cacheLoaded[`${args[0].year}-${args[0].month}.${args[0].country}`] || 0)));
        return this.memoizers.getHolidayEventsByMonth(paramsReq, filterReq);
    }

    getCountryEventsByDay(params: IDayEventsParams, filter: IEventsFilter | undefined = {}) {
        return this.getEventsByMonth(params, filter).monthEvents?.[params.day]?.holiday || [];
    }

    // noinspection JSUnusedGlobalSymbols
    getMyEventsByDay(params: IDayEventsParams) {
        return this.getEventsByMonth(_.omit(params, 'day')).monthEvents?.[params.day]?.my || [];
    }

    // noinspection JSUnusedGlobalSymbols
    getSuggestedEventsByDay(params: IDayEventsParams) {
        return this.getEventsByMonth(_.omit(params, 'day')).monthEvents?.[params.day]?.suggested || [];
    }

    // noinspection JSUnusedGlobalSymbols
    getChainEventsByDay(params: IDayEventsParams) {
        return this.getEventsByMonth(_.omit(params, 'day')).monthEvents?.[params.day]?.chain || [];
    }

    getDayEventById(id: string): IDayEvent | null {
        // todo, optimize. Currently, we are looping all days of month to find event by ID.
        // Solution: isApproved/isSuggested etc. should be added in the event object itself.
        const { monthEvents } = this.getEventsByMonth();

        // eslint-disable-next-line no-restricted-syntax
        for (const events of Object.values(monthEvents)) {
            const { my, suggested, holiday, chain } = events;

            const myEvent = my.find(x => x.id === id) || null;
            if (myEvent) {
                return {
                    isApproved: true,
                    event: myEvent,
                };
            }

            const suggestedEvent = suggested.find(x => x.id === id) || null;
            if (suggestedEvent) {
                return {
                    isSuggested: true,
                    event: suggestedEvent,
                };
            }

            const holidayEvent = holiday.find(x => x.id === id) || null;

            if (holidayEvent) {
                return {
                    isHoliday: true,
                    event: holidayEvent,
                };
            }

            const chainEvent = chain.find(x => x.id === id) || null;
            if (chainEvent) {
                return {
                    isChain: true,
                    event: chainEvent,
                };
            }
        }

        return null;
    }

    async getDayEventByIdFromCacheOrFromApi(id: string): Promise<IDayEvent | null> {
        if (id) {
            let tries = 0;
            let event = null;
            while (tries < 3) {
                // eslint-disable-next-line no-await-in-loop
                event = await this.getDayEventById(id);
                if (event) {
                    break;
                }
                // eslint-disable-next-line no-await-in-loop
                await CarUtils.sleep(350);
                tries++;
            }

            if (!event) {
                // this action only work with holidays because only on holidays we have mix between ci holidays and chain holidays
                const holiday = await this.getHolidayById(id);
                if (holiday) {
                    event = {
                        isHoliday: true,
                        event: holiday,
                    };
                }
            }
            return event;
        }
        return null;
    }

    async getHolidayById(id: string) {
        const holiday = await this.eventsApiService.getHolidayById(id);
        return holiday;
    }

    async addEvent(newEvent: EventsModel): Promise<ValidationError[]> {
        let validationErrors: ValidationError[] = await validate(newEvent);

        if (validationErrors.length > 0) {
            return validationErrors;
        }

        try {
            const { user, currentHotelId, isCarUser } = this.userService;
            const { isChainOrClusterUser, viewAs } = this.userService;
            const isClusterUser = isChainOrClusterUser && viewAs !== 'hotel';

            const { currentCompset } = this.compsetsService;
            const marketId = currentCompset ? currentCompset.marketId : currentHotelId;

            if (!user || (!marketId && !isCarUser) || (!currentHotelId && isCarUser)) {
                return validationErrors;
            }

            if (isClusterUser) {
                await this.eventsApiService.createChainEvent({
                    ...newEvent, marketId: Number(marketId || this.userService.currentHotelId), ownerId: user.id.toString(),
                });
            } else {
                await this.eventsApiService.createLocalEvent({
                    ...newEvent, marketId: Number(marketId || this.userService.currentHotelId), ownerId: user.id.toString(),
                });
            }
            this.resetEvents();
        } catch (error) {
            if (error instanceof BadRequestException) {
                validationErrors = this.updateValidationErrors(validationErrors, error.message);
            } else {
                throw error;
            }
        }
        return validationErrors;
    }

    async updateEvent(dayEvent: IDayEvent): Promise<ValidationError[]> {
        const { event } = dayEvent;
        let validationErrors: ValidationError[] = await validate(event);

        if (validationErrors.length > 0) {
            return validationErrors;
        }

        try {
            await this.eventsApiService.updateLocalEvent(event);
            this.resetEvents();
        } catch (error) {
            if (error instanceof BadRequestException) {
                validationErrors = this.updateValidationErrors(validationErrors, error.message);
            } else {
                throw error;
            }
        }
        return validationErrors;
    }

    async validateEvent(dayEvent: EventsModel): Promise<ValidationError[]> {
        const validationErrors: ValidationError[] = await validate(dayEvent);
        if (validationErrors.length > 0) {
            return validationErrors;
        }
        return [];
    }

    async updateChainEvent(dayEvent: IDayEvent): Promise<{ error: ValidationError[], id?: string}> {
        const { event } = dayEvent;
        let id = '';
        let validationErrors: ValidationError[] = await validate(event);
        if (validationErrors.length > 0) {
            return { error: validationErrors };
        }

        try {
            const updateData = await this.eventsApiService.updateChainEvent(event);
            await CarUtils.sleep(20);
            // eslint-disable-next-line no-underscore-dangle
            id = updateData._id;
            this.storeState.additionalCountryCodeAfterUpdate = updateData.country;
            this.resetEvents();
        } catch (error) {
            if (error instanceof BadRequestException) {
                validationErrors = this.updateValidationErrors(validationErrors, error.message);
            } else {
                throw error;
            }
        }
        return { error: validationErrors, id };
    }

    async removeEvent(eventId: string): Promise<ValidationError[]> {
        let validationErrors: ValidationError[] = [];
        try {
            await this.eventsApiService.removeLocalEvent(eventId);
            this.resetEvents();
        } catch (error) {
            if (error instanceof BadRequestException) {
                validationErrors = this.updateValidationErrors(validationErrors, error.message);
            } else {
                throw error;
            }
        }
        return validationErrors;
    }

    async approveEvent(eventId: string): Promise<ValidationError[]> {
        let validationErrors: ValidationError[] = [];
        try {
            await this.eventsApiService.approveEvents(eventId);
            this.resetEvents();
        } catch (error) {
            if (error instanceof BadRequestException) {
                validationErrors = this.updateValidationErrors(validationErrors, error.message);
            } else {
                throw error;
            }
        }
        return validationErrors;
    }

    async ignoreEvent(eventId: string): Promise<ValidationError[]> {
        let validationErrors: ValidationError[] = [];
        try {
            await this.eventsApiService.ignoreEvents([eventId]);
            this.resetEvents();
        } catch (error) {
            if (error instanceof BadRequestException) {
                validationErrors = this.updateValidationErrors(validationErrors, error.message);
            } else {
                throw error;
            }
        }
        return validationErrors;
    }

    protected addDefaultFilterValues(filter: IEventsFilter | undefined): Required<IEventsFilter> {
        // Don't use this.storeState.settings directly, we need eventsFilterService constructor executed first (in order to get the defaults)
        const { countries, types, status } = this.eventsFilterService.settings;
        // Add default filters from store
        return {
            countries,
            types,
            status,
            ...filter,
        };
    }

    protected addDefaultMonthParams(params: IMonthEventsParams): Required<IMonthEventsParams> {
        // Add default month and year from documentFiltersService
        return {
            month: this.documentFiltersService.month,
            year: this.documentFiltersService.year,
            country: '',
            ...params,
        };
    }

    /**
     * Use undefined filter to not filter.
     */
    getEventsByMonth(params: IMonthEventsParams = {}, filter: IEventsFilter | undefined = {}, opts: { updateFilters: boolean } = { updateFilters: false })
        : { monthEvents: { [day: number]: EventsItemModel }, countriesList: { [countryCode: string]: string }, countries: string[] } {
        const paramsReq = this.addDefaultMonthParams(params);
        const filterReq = filter ? this.addDefaultFilterValues(filter) : undefined;
        // ensure that no day is passed if we call this method with IDayEventsParams
        // @ts-ignore
        this.memoizers.getEventsByMonth = this.memoizers.getEventsByMonth || _.memoize((p: Required<IMonthEventsParams>, f: Required<IEventsFilter> | undefined) => {
            const monthEvents: { [day: number]: EventsItemModel } = {};
            const monthEventsData = this.getHolidayEventsByMonth(p, f);
            const myEvents = this.populateMonthEventsByDays(this.getMyEventsByMonth(p, f), p.month, p.year);
            const suggestedEvents = this.populateMonthEventsByDays(this.getSuggestedEventsByMonth(p, f), p.month, p.year);
            const holidayEvents = this.populateMonthEventsByDays(monthEventsData?.holiday, p.month, p.year);
            const chainEvents = this.populateMonthEventsByDays(this.getChainEventsByMonth(p, f), p.month, p.year);

            const lastDayOfMonth = new Date(p.year, p.month + 1, 0).getDate();

            for (let day = 1; day <= lastDayOfMonth; day++) {
                if (myEvents[day]) {
                    if (!monthEvents[day]) {
                        monthEvents[day] = new EventsItemModel();
                    }
                    monthEvents[day].my = myEvents[day];
                }
                if (suggestedEvents[day]) {
                    if (!monthEvents[day]) {
                        monthEvents[day] = new EventsItemModel();
                    }
                    monthEvents[day].suggested = suggestedEvents[day];
                }
                if (holidayEvents[day]) {
                    if (!monthEvents[day]) {
                        monthEvents[day] = new EventsItemModel();
                    }
                    monthEvents[day].holiday = holidayEvents[day];
                }
                if (chainEvents[day]) {
                    if (!monthEvents[day]) {
                        monthEvents[day] = new EventsItemModel();
                    }
                    monthEvents[day].chain = chainEvents[day];
                }
            }
            return { monthEvents, countriesList: monthEventsData.countriesList, countries: monthEventsData.countries };
        }, (...args: any) => JSON.stringify(args.concat(this.storeState.cacheLoaded[`${args[0].year}-${args[0].month}.${args[0].country}`] || 0)));
        return this.memoizers.getEventsByMonth(paramsReq, filterReq);
    }

    /**
     * Use undefined filter to not apply.
     */
    getEventsByDay(params: IDayEventsParams, filter: IEventsFilter | undefined = {}, updateFilters: boolean = false)
        : { dayEvents: IDayEvent[], isUpdated: boolean } {
        const monthEventsData = this.getEventsByMonth(_.omit(params, 'day'), filter);
        let isUpdated = false;
        if (updateFilters && Object.keys(monthEventsData.monthEvents).length) {
            isUpdated = true;
            this.eventsFilterService.storeState.settings.countries = monthEventsData.countries;
            this.eventsFilterService.storeState.settings.countriesList = monthEventsData.countriesList;
        }
        if (params.month === this.documentFiltersService.month && this.storeState.forceUpdate && Object.keys(monthEventsData.monthEvents).length) {
            this.storeState.forceUpdate = false;
            const countriesFromCountiesList = Object.keys(monthEventsData.countriesList);
            const selectCountry = _.uniq([...this.eventsFilterService.storeState.settings.countries, this.storeState.additionalCountryCodeAfterUpdate]).filter(pos => countriesFromCountiesList.includes(pos));
            this.eventsFilterService.storeState.settings.countries = selectCountry;
            this.eventsFilterService.storeState.settings.countriesList = monthEventsData.countriesList;
            this.storeState.additionalCountryCodeAfterUpdate = '';
        }
        const allEvents = monthEventsData?.monthEvents?.[params.day] || {};
        const dayEvents: IDayEvent[] = [];

        (allEvents.holiday || []).forEach((event: EventsModel) => {
            dayEvents.push({
                isHoliday: true,
                event,
            });
        });
        (allEvents.my || []).forEach((event: EventsModel) => {
            dayEvents.push({
                isMy: true,
                event,
            });
        });
        (allEvents.suggested || []).forEach((event: EventsModel) => {
            dayEvents.push({
                isSuggested: true,
                event,
            });
        });
        (allEvents.chain || []).forEach((event: EventsModel) => {
            dayEvents.push({
                isChain: true,
                event,
            });
        });
        return { dayEvents, isUpdated };
    }

    private populateMonthEventsByDays(events: EventsModel[], month: Month, year: Year) {
        const monthEvents: { [day: number]: EventsModel[] } = {};
        const daysInMonth = moment.utc(`${year}-${month + 1}`, 'YYYY-MM').daysInMonth();
        for (let i = 1; i <= daysInMonth; i += 1) {
            const currentDate = moment.utc([year, month + 1, i], 'YYYY-MM-DD');
            events?.forEach(event => {
                if (event.startDate && event.endDate) {
                    if (currentDate.isBetween(moment.utc(event.startDate, 'YYYY-MM-DD'), moment.utc(event.endDate, 'YYYY-MM-DD'), undefined, '[]')) {
                        if (!monthEvents[i]) {
                            monthEvents[i] = [];
                        }
                        monthEvents[i].push(event);
                    }
                }
            });
        }

        return monthEvents;
    }

    updateValidationErrors(validationErrors: ValidationError[], message: string): ValidationError[] {
        const error = new ValidationError();
        error.constraints = {
            message,
        };
        return [...validationErrors, ...[error]];
    }

    hasHolidayEventsByDay(params: IDayEventsParams, filter: IEventsFilter = {}): boolean {
        const dayEvents = this.getEventsByMonth(_.omit(params, 'day'), filter).monthEvents?.[params.day]?.holiday || [];
        return Boolean(dayEvents.length);
    }

    hasLocalEventsByDay(params: IDayEventsParams, filter: IEventsFilter | undefined = {}): boolean {
        // Don't call getMyEventsByDay/getSuggestedEventsByDay/getChainEventsByDay separately, use fast single call to getEventsByMonth
        const dayEvents = this.getEventsByMonth(_.omit(params, 'day'), filter).monthEvents?.[params.day] || false;
        return dayEvents && Boolean(dayEvents?.my.length || dayEvents?.suggested.length || dayEvents?.chain.length);
    }
}
