import { Expose, Transform } from 'class-transformer';

export class CompsetsLogDeletedItemModel {
    @Expose()
    @Transform((_, plain) => plain.vendors)
    vendors!: string[];

    @Expose()
    @Transform((_, plain) => plain.country)
    country!: string;

    @Expose()
    @Transform((_, plain) => plain.data_source)
    dataSource!: string;
}
