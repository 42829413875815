import { Inject, injectable } from 'inversify-props';
import { plainToClass } from 'class-transformer';
import Month from '@/modules/common/types/month.type';
import Year from '@/modules/common/types/year.type';
import EventsDTO from '@/modules/events/dto/events.dto';
import EventsModel from '@/modules/events/models/events.model';
import ApiService, { ApiServiceS } from '@/modules/common/services/api.service';
import ChainEventsDTO from './dto/chainEvent.dto';
import CarsHolidaysCountriesModel from './models/cars-holidays-countries.model';

export const EventsApiServiceS = Symbol.for('EventsApiServiceS');
@injectable(EventsApiServiceS as unknown as string)
export default class EventsApiService {
    @Inject(ApiServiceS) private apiService!: ApiService;

    async getAllCountries(): Promise<{[code: string]: string}> {
        const { data } = await this.apiService.get('car/get-all-countries');

        return data;
    }

    async getHolidayById(id: string): Promise<EventsModel | null> {
        const { data } = await this.apiService.get(`/holiday/get-holiday-by-id/${id}`);

        if (!data) {
            return null;
        }

        return plainToClass(EventsModel, <EventsDTO> data, { excludeExtraneousValues: true });
    }

    async getChainEvents(month: Month, year: Year): Promise<EventsModel[] | null> {
        const { data } = await this.apiService.get('event/chain', { month: month + 1, year });

        if (!data) {
            return null;
        }
        return plainToClass(EventsModel, <EventsDTO[]> data, { excludeExtraneousValues: true });
    }

    async getMyHotelEvents(month: Month, year: Year): Promise<EventsModel[] | null> {
        const { data } = await this.apiService.get('event/hotel', { month: month + 1, year });

        if (!data) {
            return null;
        }
        return plainToClass(EventsModel, <EventsDTO[]> data, { excludeExtraneousValues: true });
    }

    async getSuggestedHotelEvents(month: Month, year: Year): Promise<EventsModel[] | null> {
        const { data } = await this.apiService.get('event/hotel', { status: 'suggested', month: month + 1, year });

        if (!data) {
            return null;
        }
        return plainToClass(EventsModel, <EventsDTO[]> data, { excludeExtraneousValues: true });
    }

    async getMyCarEvents(month: Month, year: Year): Promise<EventsModel[] | null> {
        const { data } = await this.apiService.get('event/cr-events', { month: month + 1, year });

        if (!data) {
            return null;
        }
        return plainToClass(EventsModel, <EventsDTO[]> data, { excludeExtraneousValues: true });
    }

    async getSuggestedCarEvents(month: Month, year: Year): Promise<EventsModel[] | null> {
        const { data } = await this.apiService.get('event/cr-events', { status: 'suggested', month: month + 1, year });

        if (!data) {
            return null;
        }
        return plainToClass(EventsModel, <EventsDTO[]> data, { excludeExtraneousValues: true });
    }

    async getHolidaysEvents(month: Month, year: Year, countryCodes?: string[]): Promise<EventsModel[] | null> {
        const codes = countryCodes ? countryCodes.join(',') : undefined;
        const { data } = await this.apiService.get('holiday', { month: month + 1, year, country_code: codes });

        if (!data) {
            return null;
        }
        // TODO: Add month filter when it will be enabled on server
        return plainToClass(EventsModel, <EventsModel[]> data, { excludeExtraneousValues: true });
    }

    async getChainHolidaysEvents(chainId: string, month: Month, year: Year, countryCodes?: string[]) {
        const params: Record<any, any> = {
            chain_id: chainId, month: month + 1, year,
        };
        if (countryCodes && countryCodes.length) {
            params.country_codes = countryCodes;
        }
        const { data } = await this.apiService.get('/holiday/chain-holidays', params);
        return plainToClass(EventsModel, <EventsModel[]> data, { excludeExtraneousValues: true });
    }

    async createLocalEvent(event: EventsModel): Promise<EventsModel | null> {
        const eventDto = plainToClass(EventsDTO, <EventsModel> event, { excludeExtraneousValues: true });

        const { data } = await this.apiService.post('event', eventDto);

        if (!data) {
            return null;
        }

        return plainToClass(EventsModel, <EventsDTO> data, { excludeExtraneousValues: true });
    }

    async createChainEvent(event: EventsModel): Promise<EventsModel | null> {
        const eventDto = plainToClass(EventsDTO, <EventsModel> event, { excludeExtraneousValues: true });

        const { data } = await this.apiService.post('event/chain', eventDto);

        if (!data) {
            return null;
        }

        return plainToClass(EventsModel, <EventsDTO> data, { excludeExtraneousValues: true });
    }

    async updateLocalEvent(event: EventsModel): Promise<void> {
        const eventDto = plainToClass(EventsDTO, <EventsModel> event, { excludeExtraneousValues: true, groups: ['update'] });
        await this.apiService.put('event', eventDto);

        // if (!data) {
        //     return null;
        // }
        //
        // return plainToClass(EventsModel, <EventsModel> data, { excludeExtraneousValues: true });
    }

    async updateChainEvent(event: EventsModel): Promise<any> {
        const eventDto = plainToClass(ChainEventsDTO, <Partial<ChainEventsDTO>> event, { excludeExtraneousValues: true, groups: ['update'] });
        eventDto.country = eventDto.country ? eventDto.country.toUpperCase() : eventDto.country;
        if (!eventDto.expected_nog) {
            eventDto.expected_nog = 0;
        }
        const res = await this.apiService.put('holiday/update-chain-holiday', eventDto);
        return res.data;
    }

    async removeLocalEvent(eventId: string): Promise<void> {
        await this.apiService.delete(`event/${eventId}`);
    }

    async approveEvents(eventId: string): Promise<void> {
        await this.apiService.put(`event/${eventId}`);
    }

    async ignoreEvents(eventIds: string[]): Promise<void> {
        await this.apiService.post('event/ignore', { eventIds });
    }

    async getCarHolidaysCountries(chainId: string): Promise<CarsHolidaysCountriesModel> {
        const { data } = await this.apiService.get(`holiday/chain-holidays-countries/${chainId}`);
        return plainToClass(CarsHolidaysCountriesModel, <CarsHolidaysCountriesModel> data, { excludeExtraneousValues: true });
    }
}
