import { Expose, Transform } from 'class-transformer';

export interface IRentalCarVendorsPerCountryDocument {
    // eslint-disable-next-line camelcase
    [country_name: string]: {
        // eslint-disable-next-line camelcase
        [data_source: string]: string[],
    };
}

export interface IUnsetVendorPerCountry {
    countryName: string;
    dataSource: string;
}

export interface IRentalCarVendorsPerCountryDiff {
    set: IRentalCarVendorsPerCountryDocument;
    unset?: IUnsetVendorPerCountry[];
}

export default class CarsCompSetModel {
    @Expose()
    @Transform((_, plain) => plain)
    data!: IRentalCarVendorsPerCountryDocument;
}
