import { Expose, Transform } from 'class-transformer';
import {
    IsNumber, IsString, IsBoolean, IsOptional,
} from 'class-validator';
import VEHICLE_TYPE from '../constants/vehicle-type.constant';

export default class CarsPriceHistoryDocumentItemModel {
    @Expose()
    @Transform((_, plain) => Number(plain.price_total))
    @IsNumber()
    priceTotal!: number;

    @Expose()
    @Transform((_, plain) => Number(plain.price_net))
    @IsNumber()
    priceNet!: number;

    @Expose()
    @Transform((_, plain) => (plain.price_shown !== undefined ? Number(plain.price_shown) : plain.price_shown))
    @IsNumber()
    priceShown!: number;

    @Expose()
    @Transform((_, plain) => Number(plain.price_total))
    @IsNumber()
    calculatedPrice!: number;

    @Expose()
    @Transform((_, plain) => plain.car_description)
    @IsString()
    brand!: string;

    @Expose()
    @Transform((_, plain) => plain.sipp_code)
    @IsString()
    sippCode!: string;

    @Expose()
    @Transform((_, plain) => plain.customer_sipp_code)
    @IsString()
    customerSippCode!: string;

    @Expose()
    @Transform((_, plain) => !!plain.price_shown || !!plain.price_net || !!plain.price_total)
    @IsBoolean()
    isAvailable!: boolean;

    @Expose()
    @Transform((_, plain) => plain.fuel_type)
    @IsOptional()
    fuelType!: string;

    @Expose()
    @IsString()
    transmission!: string;

    isMainCar: boolean = false;

    @Expose()
    @Transform((_, plain) => plain.vehicle_type)
    @IsOptional()
    vehicleType!: VEHICLE_TYPE | null;

    @Expose()
    @Transform((_, plain) => plain.rate_type)
    @IsString()
    paymentTerms!: string;

    @Expose()
    @Transform((_, plain) => plain.mileage_policy)
    @IsString()
    mileage!: string;
}
