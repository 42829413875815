import { Expose, plainToClass, Transform } from 'class-transformer';
import { CompsetsLogChangeItemModel } from './compsets-log-change-item.model';
import { CompsetsLogDeletedItemModel } from './compsets-log-deleted-items.model';

export class CompsetsLogItemModel {
    @Expose()
    @Transform((_, plain) => (
        plainToClass(CompsetsLogChangeItemModel, <CompsetsLogChangeItemModel[]> plain.changes, { excludeExtraneousValues: true })
    ))
    changes!: CompsetsLogChangeItemModel[];

    @Expose()
    @Transform((_, plain) => (
        plainToClass(CompsetsLogDeletedItemModel, <CompsetsLogDeletedItemModel[]> plain.deleted_items, { excludeExtraneousValues: true })
    ))
    deletedItems!: CompsetsLogDeletedItemModel[];

    @Expose()
    @Transform((_, plain) => plain.version)
    version!: number;

    @Expose()
    @Transform((_, plain) => plain.update_timestamp && new Date(plain.update_timestamp))
    updateDate!: Date;

    @Expose()
    @Transform((_, plain) => plain.updated_by)
    email!: string;
}
