import { Module, VuexModule } from 'vuex-module-decorators';
import LoadingModel from '@/modules/common/models/loading.model';
import SpotChecksDocumentModel from '../models/spotchecks-document.model';
import SpotChecksSettingsModel from '../models/spotchecks-settings.model';

export interface ISpotCheckDocument {
    [dataSource: string]: { [locationId: string]: SpotChecksDocumentModel }
}

export interface ISpotCheckAvailabilityCount {
    [dataSource: string]: {
        [provider: string]: {
            [location: string]: number;
        };
    };
}

@Module
export default class SpotChecksStore extends VuexModule {
    filtersReady: boolean = false;
    loading: LoadingModel = new LoadingModel();
    settings: SpotChecksSettingsModel = new SpotChecksSettingsModel();
    documents: ISpotCheckDocument | null = null;
    availableDays: ISpotCheckAvailabilityCount = {};
    notAvailableDays: ISpotCheckAvailabilityCount = {};
    locationQuery: string | null = null;
}
