import { COUNTRIES_ANY } from '@/modules/cars/constants/car-filter-types.constant';

export interface ICustomSelectValue {
    name: string;
    value: string;
}

export default class SpotChecksSettingsModel {
    country: string | null = null;
    locationIds: string[] = [];
    dataSources: string[] = [];
    competitors: string[] = [];
    pos: string | null = null;
    lor: number | null = null;
    date: string = new Date().toDateString();
    isClosedOnly: boolean = false;
}
