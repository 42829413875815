import { Module, VuexModule } from 'vuex-module-decorators';
import LoadingModel from '@/modules/common/models/loading.model';
import SippItemModel from '@/modules/cars/modules/sipp/models/sipp-item.model';
import SippSettingsModel from '@/modules/cars/modules/sipp/models/sipp-settings.model';
import {
    TRANSMISSION_ANY, PROVIDER_ANY, VEHICLE_TYPE_ANY, COUNTRIES_ANY, FUEL_TYPE_ANY, BRAND_ANY, POS_ANY, CATEGORY_NAME,
} from '@/modules/cars/constants/car-filter-types.constant';
import SippDiffModel from '@/modules/cars/modules/sipp/models/sipp-diff.model';
import SippFiltersModel from '@/modules/cars/modules/sipp/models/sipp-filters.model';
import SippLogItemModel from '@/modules/cars/modules/sipp/models/sipp-log-item.model';
import SippLogChangeItemModel from '@/modules/cars/modules/sipp/models/sipp-log-change-item.model';

@Module
export default class SippStore extends VuexModule {
    allowedBrand: string[] = [];
    documents: SippItemModel[] | null = null;
    previewDocuments: SippItemModel[] | null = null;
    historyLogs: {
        logs: SippLogItemModel[] | null,
        version: number | null,
        changes: SippLogChangeItemModel[] | null,
        loading: LoadingModel,
        showChangesOnly: boolean;
    } = {
        logs: null,
        version: null,
        changes: null,
        loading: new LoadingModel(),
        showChangesOnly: false,
    };
    totalCount: number | null = null;
    totalPages: number = 1;
    page: number = 1;
    isFilterReseted: boolean = false;
    isPopupClose: boolean = true;

    settings: SippSettingsModel = {
        carName: '',
        standardCode: '',
        customCode: '',
        categoryName: '',
        transmission: TRANSMISSION_ANY,
        carClasses: 'Any',
        pos: POS_ANY,
        provider: PROVIDER_ANY,
        vehicleType: VEHICLE_TYPE_ANY,
        countryName: COUNTRIES_ANY,
        fuelType: FUEL_TYPE_ANY,
        carBrand: BRAND_ANY,
        isVans: false,
    };

    defaultFilters: any = {
        carDescription: '',
        standardCode: '',
        customCode: '',
        categoryName: CATEGORY_NAME,
        transmissionValue: 'Any',
        carClassesValue: 'Any',
        carNormalizedClassesValue: 'Any',
        posValue: POS_ANY,
        providersValue: PROVIDER_ANY,
        vehicleType: VEHICLE_TYPE_ANY,
        countryValue: COUNTRIES_ANY,
        fuelTypeValue: FUEL_TYPE_ANY,
        carBrand: BRAND_ANY,
        vehicleValue: 'Any',
    };

    filters: SippFiltersModel = {
        carName: '',
        standardCode: '',
        customCode: '',
        transmission: null,
        carClasses: null,
        pos: null,
        providers: null,
        vehicleTypes: null,
        fuelTypes: null,
        carBrands: null,
        normalizedCarCategory: null,
        brandName: null,
        countryName: null,
        countryCode: null,
        normalizedCarCategoryPerPos: null,
    };
    newCategories: string[] = [];
    diffDocuments: SippDiffModel[] = [];

    invalidItems: { [id: string]: boolean } = { };

    loading: LoadingModel = new LoadingModel();
    loadingFilters: LoadingModel = new LoadingModel();
    compsetFilters: boolean = false;
}
