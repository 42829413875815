import ConfigService, { ConfigServiceS } from '@/modules/config/config.service';
import UserModel from '@/modules/user/models/user.model';
import type { CoralogixRum as ICoralogixRum } from '@coralogix/browser';
import { container } from 'inversify-props';
import { version } from '../../package.json';

export const CoralogixPlugin = {
    async install() {
        if (window.location.hostname === 'localhost') {
            // eslint-disable-next-line no-console
            console.warn('Coralogix initialization prevented due to localhost');
            return;
        }

        const environment = window.location.host.split('.')[0];
        const { CoralogixRum } = window;

        if (!CoralogixRum) {
            return;
        }

        const config = container.get<ConfigService>(ConfigServiceS);

        CoralogixRum.init({
            application: 'CI Frontend',
            environment,
            public_key: config.coralogixKey || '',
            coralogixDomain: 'EU1',
            version: `v${version}`,
            sessionRecordingConfig: {
                enable: true,
                autoStartSessionRecording: true,
                recordConsoleEvents: true,
                sessionRecordingSampleRate: 100,
            },
        });
    },

    updateUserContext(user: UserModel) {
        const { CoralogixRum } = window;

        if (!CoralogixRum) {
            return;
        }

        CoralogixRum.setUserContext({
            user_id: user.id!,
            user_email: user.email,
            user_name: [user.firstName, user.lastName].join(' '),
            user_metadata: {
                level: user.level,
                viewAs: user.viewAs,
                role: user.role,
                chain_id: user.chainId,
                currentHotelId: user.viewAs === 'hotel' || user.level === 'hotel'
                    ? user.currentHotelId
                    : undefined,
            },
        });
    },
};

declare global {
    interface Window {
        CoralogixRum: typeof ICoralogixRum;
    }
}
