export enum CarAlertTypeList {
    RATE_DIFF = 'Rates Difference',
    BRAND_DIFF = 'My Brand Rate Change',
    COMPSET_DIFF = 'Lowest/Highest in compset',
    COMPETITORS_DIFF = 'Competitors Rate Change',
    TREND_DIFF = 'Trend detected',
}

export enum CarAlertSubTitle {
    RATE_DIFF = 'My brand rate is higher/lower than my competitor by (_)%',
    COMPSET_DIFF = 'My brand price is Lowest/Highest of the compset',
    COMPETITORS_DIFF = 'When a change in one of my competitors was detected (by %__ )',
    BRAND_DIFF = 'When a change in my brand was detected (by %__ )',
    TREND_DIFF = 'At least (__) provider changed their rate by more than (__)%',
}

export enum CarAlertType {
    SIPP_CODE = 'sipp_code',
    CAR_CATEGORY = 'car_category',
    RATE_DIFF = 'rate_diff',
    COMPSET_DIFF = 'compset_diff',
    COMPETITORS_DIFF = 'competitors_diff',
    BRAND_DIFF = 'brand_diff',
    TREND_DIFF = 'trend_diff',
}

export enum CarRateAlertType {
    RATE_DIFF = CarAlertType.RATE_DIFF,
    COMPSET_DIFF= CarAlertType.COMPSET_DIFF,
    COMPETITORS_DIFF = CarAlertType.COMPETITORS_DIFF,
    BRAND_DIFF = CarAlertType.BRAND_DIFF,
    TREND_DIFF = CarAlertType.TREND_DIFF,
}
