import { Expose, Transform } from 'class-transformer';

export default class ChainEventsDTO {
    @Expose({ groups: ['update'] })
    // eslint-disable-next-line no-underscore-dangle
    @Transform((_, plain) => plain.id)
    id!: string;

    @Expose()
    // eslint-disable-next-line no-underscore-dangle
    @Transform((_, plain) => plain.name)
    // eslint-disable-next-line camelcase
    event_name!: string;

    @Expose()
    // eslint-disable-next-line no-underscore-dangle
    @Transform((_, plain) => plain.startDate)
    // eslint-disable-next-line camelcase
    start_date!: string;

    @Expose()
    // eslint-disable-next-line no-underscore-dangle
    @Transform((_, plain) => plain.endDate)
    // eslint-disable-next-line camelcase
    end_date!: string;

    @Expose()
    // eslint-disable-next-line no-underscore-dangle
    @Transform((_, plain) => plain.countryCode)
    country!: string;

    @Expose()
    // eslint-disable-next-line no-underscore-dangle
    @Transform((_, plain) => plain.eventCodeName)
    // eslint-disable-next-line camelcase
    event_code_name!: string;

    @Expose()
    // eslint-disable-next-line no-underscore-dangle
    @Transform((_, plain) => plain.numberOfGuests)
    // eslint-disable-next-line camelcase
    expected_nog!: number;

    @Expose()
    // eslint-disable-next-line no-underscore-dangle
    @Transform((_, plain) => plain.description)
    // eslint-disable-next-line camelcase
    event_description!: string;
}
