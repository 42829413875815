import OpenTelemetryService, { OpenTelemetryPublicInterface } from '@/modules/open-telemetry/open-telemetry.service';
import { trace } from '@opentelemetry/api';
import { ZoneContextManager } from '@opentelemetry/context-zone';
import { Resource } from '@opentelemetry/resources';
import { WebTracerProvider } from '@opentelemetry/sdk-trace-web';
import { SEMRESATTRS_SERVICE_NAME } from '@opentelemetry/semantic-conventions';
import { injectable } from 'inversify-props';
import VueRouter from 'vue-router';

export const CarOpenTelemetryServiceS = Symbol.for('CarOpenTelemetryServiceS') as unknown as string;

@injectable(CarOpenTelemetryServiceS)
export default class CarOpenTelemetryService extends OpenTelemetryService implements OpenTelemetryPublicInterface {
    protected allowedFilterKeys: string[] = ['lor', 'pos', 'year', 'month', 'mileage', 'paymentTerms', 'transmission', 'country',
        'pickUpCityCode', 'priceShown', 'dataSource', 'countries', 'status', 'types', 'pickUpCityCodes', 'providers'];

    init(router: VueRouter) {
        if (this.isLocal) {
            return;
        }

        this.router = router;
        const provider = new WebTracerProvider();
        provider.register({
            contextManager: new ZoneContextManager(),
        });
        this.tracer = trace.getTracer('fe-tracer');
    }
}
