import { Expose, Transform } from 'class-transformer';
import { IsNumber } from 'class-validator';

export interface IPhaseAvailability {
    screenshot: string;
    av: boolean;
}

export interface ISpotCheckUINA {
    [vendor: string]: {
        [phase: number]: IPhaseAvailability;
    };
}

export interface ISpotCheckRequestFilter {
    date: string;
    providers: string[];
    /* eslint-disable camelcase */
    location_ids: string[];
    pos: string;
}

export interface ISpotChecksPhaseAvailability {
    screenshot: string;
    av: boolean;
    /* eslint-disable camelcase */
    pick_up_date_time: string;
}

export default class SpotChecksDocumentModel {
    @Expose()
    // eslint-disable-next-line no-underscore-dangle
    @Transform((_, plain) => Number(plain._id))
    @IsNumber()
    id!: number;

    @Expose()
    @Transform((_, plain) => new Date(plain.date))
    @IsNumber()
    date!: Date;

    @Expose()
    @Transform((_, plain) => String(plain.location_id))
    @IsNumber()
    locationId!: string;

    @Expose()
    @Transform((_, plain) => String(plain.pos))
    @IsNumber()
    pos!: string;

    @Expose()
    @Transform((_, plain) => String(plain.provider))
    @IsNumber()
    provider!: string;

    @Expose()
    @Transform((_, plain) => plain.availability)
    availability!: ISpotCheckUINA;
}
