import { Expose, Transform } from 'class-transformer';

export class CompsetsLogChangeItemModel {
    @Expose()
    @Transform((_, plain) => plain.old_vendors)
    oldVendors!: string[];

    @Expose()
    @Transform((_, plain) => plain.new_vendors)
    newVendors!: string[];

    @Expose()
    @Transform((_, plain) => plain.country)
    country!: string;

    @Expose()
    @Transform((_, plain) => plain.data_source)
    dataSource!: string;
}
