import { Expose, Transform } from 'class-transformer';

export default class CarRatesScanModel {
    @Expose()
    @Transform((_, plain) => Number(plain.document_id))
    ratesDocumentId!: number;

    @Expose()
    @Transform((_, plain) => String(plain.error_msg || ''))
    errorMsg!: string;
}
