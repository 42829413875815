import { Inject, injectable } from 'inversify-props';
import StoreFacade, { StoreFacadeS } from '@/modules/common/services/store-facade';
import HelperService, { HelperServiceS } from '@/modules/common/services/helper.service';
import CarsCompSetStore from '../../store/cars.comp-set.store';
import CarsSharedService, { CarsSharedServiceS } from '../../cars-shared.service';
import CarsApiService, { CarsApiServiceS } from '../../cars-api.service';
import { CompsetsLogChangeItemModel, CompsetsLogDeletedItemModel } from './models';
import CarsCompSetModel from '../../models/cars-comp-set.model';

export const CarCompsetsServiceS = Symbol.for('CarCompsetsServiceS');

@injectable(CarCompsetsServiceS as unknown as string)
export default class CarCompsetsService {
    @Inject(StoreFacadeS) private storeFacade!: StoreFacade;
    @Inject(CarsSharedServiceS) private carsSharedService!: CarsSharedService;
    @Inject(CarsApiServiceS) protected carApiService!: CarsApiService;
    @Inject(HelperServiceS) private helperService!: HelperService;
    readonly storeState: CarsCompSetStore = this.storeFacade.getState('CarsCompSetStore');

    public get logsChanges() {
        return this.storeState.historyLogs.changes;
    }

    public set logsChanges(value: CompsetsLogChangeItemModel[] | null) {
        this.storeState.historyLogs.changes = value;
    }

    public get deletedItems() {
        return this.storeState.historyLogs.deletedItems;
    }

    public set deletedItems(value: CompsetsLogDeletedItemModel[] | null) {
        this.storeState.historyLogs.deletedItems = value;
    }

    public get logVersion() {
        return this.storeState.historyLogs.version;
    }

    public set logVersion(value: number | null) {
        this.storeState.historyLogs.version = value;
    }

    public get showChangesOnly() {
        return this.storeState.historyLogs.showChangesOnly;
    }

    public set showChangesOnly(value: boolean) {
        this.storeState.historyLogs.showChangesOnly = value;
    }

    public get isHistoryLogsLoading(): boolean {
        return this.storeState.historyLogs.loading.isLoading();
    }

    public get compestSettingsLogs() {
        this.helperService.dynamicLoading(this.storeState.historyLogs.loading, this.loadCompestSettingsLogsData.bind(this));
        return this.storeState.historyLogs.logs;
    }

    get documentsPreview() {
        return this.storeState.previewDocuments;
    }

    set documentsPreview(value: CarsCompSetModel | null) {
        this.storeState.previewDocuments = value;
    }

    public async loadCompestSettingsLogsData(): Promise<boolean> {
        const { currentChainId } = this.carsSharedService;
        this.storeState.historyLogs.logs = await this.carApiService.getCompestSettingsLogs(currentChainId);
        return true;
    }

    public async previewCompestSettingsLog(): Promise<boolean> {
        const { version } = this.storeState.historyLogs;
        if (!version) {
            return false;
        }
        const { currentChainId } = this.carsSharedService;
        const data = await this.carApiService.previewCompestSettingsLog(version, currentChainId);
        this.storeState.previewDocuments = data;
        return true;
    }

    public async revertCompestSettingsLogs() {
        const { version } = this.storeState.historyLogs;
        if (!version) {
            return false;
        }
        const { currentChainId } = this.carsSharedService;
        await this.carApiService.revertCompestSettingsLogs(version, currentChainId);
        return true;
    }
}
