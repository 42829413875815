import { Route } from 'vue-router';
import { container } from 'inversify-props';
import ExceptionHandler, { ExceptionHandlerS } from '@/modules/common/modules/exception-handler/exception-handler.service';
import { NextFunction } from '@/router/types';
import ErrorException from '@/modules/common/modules/exception-handler/exceptions/error.exception';
import { IMiddleware } from './interfaces/middleware.interface';
import { IGuard } from './interfaces/guard.interface';

export default function use(params: (IGuard | IMiddleware)[]) {
    const fallbackPath = '/forbidden';

    return async (to: Route, from: Route, next: NextFunction) => {
        try {
            if (!params || params.length === 0) {
                return next();
            }

            const results = await Promise.all(params.map(param => param.canActivate(to, from)));

            for (let index = 0; index < results.length; index += 1) {
                const result = results[index];

                if (typeof result === 'string') {
                    return next(result);
                }

                if (!result) {
                    return next(fallbackPath);
                }
            }

            return next();
        } catch (error) {
            const exceptionHandler = container.get<ExceptionHandler>(ExceptionHandlerS);
            exceptionHandler.catch(error as Error);
            return next(false);
        }
    };
}
