import { Inject, injectable } from 'inversify-props';
import { plainToClass } from 'class-transformer';
import ApiService, { ApiServiceS } from '@/modules/common/services/api.service';
import CarsRateAlertConfigurationModel from './models/cars-rate-alert-configuration.model';
import { ICarAlertConfig } from './modules/settings/interfaces/alert-interfaces';
import { CarRateAlertType } from './modules/settings/enums/alerts.enum';
import CarsSharedService, { CarsSharedServiceS } from './cars-shared.service';

export const CarsRateAlertApiServiceS = Symbol.for('CarsRateAlertApiServiceS');
@injectable(CarsRateAlertApiServiceS as unknown as string)
export default class CarsRateAlertApiService {
    @Inject(ApiServiceS) protected apiService!: ApiService;
    @Inject(CarsSharedServiceS) protected carsSharedService!: CarsSharedService;

    protected url = 'cr-insight/alert-rate-config';

    async getAlertsConfiguration(alertType: CarRateAlertType) : Promise<CarsRateAlertConfigurationModel | null | any> {
        const params = new URLSearchParams({ alertType: alertType as unknown as string, chainId: this.carsSharedService.currentChainId });
        const { data } = await this.apiService.get(`${this.url}?${params}`);

        if (!data) {
            return null;
        }

        const carsDocument = plainToClass(CarsRateAlertConfigurationModel, data, { excludeExtraneousValues: true });
        return carsDocument;
    }

    async saveAlertConfiguration(configuration: ICarAlertConfig, alertType: CarRateAlertType) {
        const params = new URLSearchParams({ alertType: alertType as unknown as string, chainId: this.carsSharedService.currentChainId });
        return this.apiService.post(`${this.url}?${params}`, configuration);
    }

    async updateAlertConfiguration(configuration: ICarAlertConfig, id: string) {
        return this.apiService.post(`${this.url}/${id}`, configuration);
    }

    async updateAlertConfigurationStatus(id: string, status: boolean) {
        return this.apiService.put(`${this.url}/update-status/${id}`, { is_active: status });
    }

    async deleteAlertConfiguration(id: string) {
        return this.apiService.delete(`${this.url}/${id}`);
    }
}
