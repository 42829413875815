import { Inject, injectable } from 'inversify-props';
import ApiService, { ApiServiceS } from '@/modules/common/services/api.service';

export const CarsScanApiServiceS = Symbol.for('CarsScanApiServiceS');
@injectable(CarsScanApiServiceS as unknown as string)
export default class CarsScanApiService {
    @Inject(ApiServiceS) private apiService!: ApiService;

    private baseUrl = '/car/scan';

    async runOndmandScan(body: {
        /* eslint-disable camelcase */
        start_date: string,
        lor: number,
        pos: string,
        data_source: string,
        location_code: string,
        is_van: boolean,
        providers: string[],
        chain_id: string,
    }) {
        const data = await this.apiService.post(
            `${this.baseUrl}/run-car-ondemand-scan`,
            body,
        );
        return data;
    }
}
