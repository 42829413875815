import { Inject, injectable } from 'inversify-props';
import StoreFacade, { StoreFacadeS } from '@/modules/common/services/store-facade';
import SippStore from './store/sipp.store';
import CarsFiltersService, { CarsFiltersServiceS } from '../../cars-filters.service';
import { COUNTRIES_ANY, PROVIDER_NONE, PROVIDER_ANY } from '../../constants/car-filter-types.constant';

export const SippSettingsServiceS = Symbol.for('SippSettingsServiceS');
@injectable(SippSettingsServiceS as unknown as string)
export default class SippSettingsService {
    @Inject(StoreFacadeS) private storeFacade!: StoreFacade;
    @Inject(CarsFiltersServiceS) private carsFiltersService!: CarsFiltersService;

    readonly storeState: SippStore = this.storeFacade.getState('SippStore');

    get brandFilters() {
        const { carBrands } = this.storeState.filters;
        const { countryName, provider } = this.storeState.settings;
        let filteredBrands = carBrands;
        const { compsetFilters } = this.storeState;
        const { aggregateAllowedVendorsPerCountry } = this.carsFiltersService;
        if (compsetFilters && filteredBrands?.length && provider && countryName && countryName !== COUNTRIES_ANY && ![PROVIDER_NONE, PROVIDER_ANY].includes(provider)) {
            const selectedCountryName = Array.isArray(countryName) ? countryName[0] : countryName;
            filteredBrands = aggregateAllowedVendorsPerCountry?.[selectedCountryName]?.[provider]?.length > 0
                ? filteredBrands.filter(item => aggregateAllowedVendorsPerCountry[selectedCountryName][provider].includes(item))
                : filteredBrands;
        }
        return filteredBrands;
    }

    get keys() {
        return [
            'providersValue',
            'carClassesValue',
            'carNormalizedClassesValue',
            'transmissionValue',
            'posValue',
            'vehicleValue',
            'countryValue',
            'fuelTypeValue',
            'carBrand',
            'standardCode',
            'customCode',
            'carDescription',
        ];
    }

    get customCode() {
        return this.storeState.settings.customCode;
    }

    set customCode(value) {
        this.storeState.settings.customCode = value;
    }

    get standardCode() {
        return this.storeState.settings.standardCode;
    }

    set standardCode(value) {
        this.storeState.settings.standardCode = value;
    }

    get carDescription() {
        return this.storeState.settings.carName;
    }

    set carDescription(value) {
        this.storeState.settings.carName = value;
    }

    get providersValue() {
        return this.storeState.settings.provider;
    }

    set providersValue(value: string | null) {
        this.storeState.settings.provider = value;
    }

    get carClassesValue() {
        return this.storeState.settings.carClasses;
    }
    set carClassesValue(value: string | null) {
        this.storeState.settings.carClasses = value;
    }

    get carNormalizedClassesValue() {
        return this.storeState.settings.categoryName;
    }
    set carNormalizedClassesValue(value: string | null) {
        this.storeState.settings.categoryName = value;
    }

    get transmissionValue() {
        return this.storeState.settings.transmission;
    }
    set transmissionValue(value: string | null) {
        this.storeState.settings.transmission = value;
    }

    get posValue() {
        return this.storeState.settings.pos;
    }
    set posValue(value: string | null) {
        this.storeState.settings.pos = value;
    }

    get vehicleValue() {
        return this.storeState.settings.vehicleType;
    }
    set vehicleValue(value: string | null) {
        this.storeState.settings.vehicleType = value;
    }

    get countryValue() {
        return this.storeState.settings.countryName;
    }
    set countryValue(value: string | string[] | null) {
        this.storeState.settings.countryName = value;
    }

    get fuelTypeValue() {
        return this.storeState.settings.fuelType;
    }
    set fuelTypeValue(value: string | null) {
        this.storeState.settings.fuelType = value;
    }

    get carBrand() {
        return this.storeState.settings.carBrand;
    }
    set carBrand(value: string | null) {
        this.storeState.settings.carBrand = value;
    }
}
