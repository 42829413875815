






























import {
    Component, Prop, Vue,
} from 'vue-property-decorator';
import { Inject } from 'inversify-props';
import CustomNotificationService, { CustomNotificationServiceS } from '@/modules/common/modules/custom-notification/custom-notification.service';
import NotificationWrapper from '@/modules/common/modules/custom-notification/components/notification-wrapper.vue';
import NotificationItemModel from '@/modules/common/modules/custom-notification/models/notification-item.model';

@Component({
    components: {
        NotificationWrapper,
    },
})
export default class ExcelDownload extends Vue {
    @Inject(CustomNotificationServiceS) private customNotificationService!: CustomNotificationService;

    @Prop({
        required: true,
        type: Object,
    })
    item!: NotificationItemModel;

    mounted() {
        const totalIndex = this.customNotificationService.notifications
            .findIndex(item => item.id === this.notificationData.id);

        this.customNotificationService.notifications[totalIndex].clientHeight = this.$el.clientHeight;

        const index = this.customNotificationService.notifications
            .filter(item => item.position === this.notificationData.position)
            .findIndex(item => item.id === this.notificationData.id);

        this.setNewHeight(index);
    }

    get percent() {
        return this.item.progress;
    }

    get notificationData() {
        return this.item;
    }

    get fileName() {
        return this.notificationData.fileName;
    }

    get isReady() {
        return this.item.isReady;
    }

    get horizontalClass() {
        return this.notificationData.position.split('-')[1];
    }

    get verticalProperty() {
        return this.notificationData.position.split('-')[0];
    }

    get positionStyle() {
        return {
            [this.verticalProperty]: `${this.notificationData.verticalOffset}px`,
        };
    }

    get isFailed() {
        return this.item.isFailed;
    }

    setNewHeight(index: number) {
        let newOffset = 16;
        const totalIndex = this.customNotificationService.notifications
            .findIndex(item => item.id === this.notificationData.id);

        const totalHeight = this.customNotificationService.notifications
            .filter(item => item.position === this.notificationData.position)
            .filter((_, ind) => ind < index)
            .reduce((accumulator, current) => accumulator + (current.clientHeight), 0);

        newOffset = index === 0 ? 16 : newOffset * (index + 1) + totalHeight;
        this.customNotificationService.notifications[totalIndex].verticalOffset = newOffset;
    }

    handleClose() {
        this.customNotificationService.close(this.notificationData.id);
    }

    handleDownload() {
        this.customNotificationService
            .getExcelFile(this.notificationData.reportId, this.notificationData.fileName);
        this.customNotificationService.close(this.notificationData.id);
    }
}
