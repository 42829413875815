import { Inject, injectable } from 'inversify-props';
import UserService, { UserServiceS } from '@/modules/user/user.service';
import { COUNTRIES_ANY } from '@/modules/cars/constants/car-filter-types.constant';
import HelperService, { HelperServiceS } from '@/modules/common/services/helper.service';
import ParitySettingsService, { ParitySettingsServiceS } from '@/modules/cars/modules/parity/parity-settings.service';
import StoreFacade, { StoreFacadeS } from '@/modules/common/services/store-facade';
import ParityService, { ParityServiceS } from '@/modules/cars/modules/parity/parity.service';
import CarsSharedService, { CarsSharedServiceS } from '@/modules/cars/cars-shared.service';
import ParityStore from '@/modules/cars/modules/parity/store/parity.store';
import CarsFilterApiService, { CarsFilterApiServiceS } from '../../cars-filter-api.service';

export const ParityFiltersServiceS = Symbol.for('ParityFiltersServiceS');
@injectable(ParityFiltersServiceS as unknown as string)
export default class ParityFiltersService {
    @Inject(UserServiceS) private userService!: UserService;
    @Inject(StoreFacadeS) private storeFacade!: StoreFacade;
    @Inject(ParitySettingsServiceS) private paritySettingsService!: ParitySettingsService;
    @Inject(ParityServiceS) private parityService!: ParityService;
    @Inject(HelperServiceS) private helperService!: HelperService;
    @Inject(CarsFilterApiServiceS) private carsFilterApiService!: CarsFilterApiService;
    @Inject(CarsSharedServiceS) private carsSharedService!: CarsSharedService;

    readonly storeState: ParityStore = this.storeFacade.getState('ParityStore');

    private defaultFilterValues: { [key: string]: any } = {};
    private userFilterStorageKey = 'parity';

    constructor() {
        this.initFilters()
            .then(() => {
                // Remember original chain defaults on first load.
                this.defaultFilterValues = JSON.parse(JSON.stringify(this.extractUserFilterValues()));

                // Apply user defaults saved in localStorage
                const settings = this.carsSharedService.getUserFilterValues(this.userFilterStorageKey);
                if (settings) {
                    // Setup filters which affect document load
                    Object.assign(this.storeState.settings, {
                        country: settings.country,
                        pickUpCityCodes: settings.pickUpCityCodes,
                        provider: settings.provider,
                        dataSources: settings.dataSources,
                        pos: settings.pos,
                        lor: settings.lor,
                        carClasses: settings.carClasses,
                        transmission: settings.transmission,
                        mileage: settings.mileage,
                        paymentTerm: settings.paymentTerm,
                        bml: settings.bml,
                    });
                }
                // Reset specific filters on pickUpCityCode change
                this.storeFacade.watch(() => this.storeState.settings.country, async () => {
                    this.paritySettingsService.updateLocations();
                });

                this.storeState.filtersReady = true;
            });
    }

    async initFilters() {
        const { filters } = this.carsSharedService;

        if (!this.paritySettingsService.country) {
            this.paritySettingsService.country = COUNTRIES_ANY;

            if (filters.locations && !this.paritySettingsService.pickUpCities) {
                this.paritySettingsService.pickUpCities = filters.locations.map(loc => loc.locationId);
            }
        }

        if (filters.lor) {
            let { lor } = filters;
            if (this.userService && this.userService.user && this.userService.user.chainName === 'Rentalcars') {
                const lorsToInclude = [3, 7, 10, 14];
                lor = lor.filter(item => lorsToInclude.includes(item));
            } else if (this.userService && this.userService.user && this.userService.user.chainName === 'Hertz') {
                const lorsToInclude = [1, 2, 3, 4, 5, 7, 28];
                lor = lor.filter(item => lorsToInclude.includes(item));
            } else if (this.userService && this.userService.user && this.userService.user.chainName === 'Lyft') {
                const lorsToInclude = [1, 2, 5, 7, 14, 28];
                lor = lor.filter(item => lorsToInclude.includes(item));
            } else if (this.userService && this.userService.user && this.userService.user.chainName === 'Avis') {
                const lorsToInclude = [1, 7];
                lor = lor.filter(item => lorsToInclude.includes(item));
            }
            this.paritySettingsService.lor = lor;
        }

        if (filters.pos) {
            this.paritySettingsService.pos = this.getParityPos();
        }

        if (filters.mileage) {
            this.paritySettingsService.mileage = filters.mileage;
        }
        if (filters.transmission) {
            this.paritySettingsService.transmission = filters.transmission;
        }
        if (filters.paymentTerms) {
            this.paritySettingsService.paymentTerms = filters.paymentTerms;
        }
        if (filters.carClass) {
            this.paritySettingsService.carClasses = filters.carClass.filter(car => car !== 'Commercial');
        }

        this.paritySettingsService.bml = ['B', 'M', 'L', 'A'];

        return true;
    }

    get settings() {
        return this.carsSharedService.filters;
    }

    // async initCurrentFilterValues() {
    //     await this.initCountries();
    //     await this.initPickUpCity();
    //     // await this.initLor();
    //     // await this.initPos();
    // }

    get countriesFilter(): string[] {
        const { countries } = this.carsSharedService.filters;

        if (!countries) {
            return [COUNTRIES_ANY];
        }

        const countryNames = new Set([COUNTRIES_ANY].concat(Object.keys(countries)));

        return Array.from(countryNames);
    }

    getCityByCode(code: string) {
        const { locations } = this.carsSharedService.filters;

        if (!locations || !locations.length) {
            return null;
        }

        const defaultLocation = locations.find(loc => loc.locationId === code);
        return defaultLocation ? defaultLocation.locationName : null;
    }

    getParityPos(): string[] {
        const { pos, allowedParityPoss } = this.carsSharedService.filters;
        const usePoss: string[] = allowedParityPoss?.length ? allowedParityPoss : pos || [];

        if (!usePoss) {
            return [];
        }

        return usePoss;
    }

    // async initCountries() {
    //     this.paritySettingsService.country = COUNTRIES_ANY;
    // }

    // async initPickUpCity() {
    //     const { locations } = this.settings;

    //     if (locations && locations.length) {
    //         const [defaultLocation] = locations;
    //         this.paritySettingsService.pickUpCities = [defaultLocation.locationId];
    //     }
    // }

    filterPickUpCitiesByCountry(country: string) {
        const {
            locations, countries, routes, availability,
        } = this.carsSharedService.filters;

        if (!locations || !routes || !availability) {
            return [];
        }

        const availableLocations = locations.filter(location => {
            const isAvailableRoute = routes.find(route => route.pickUpLocationId === location.locationId);
            const isAvailableGroup = availability.find(group => group.id === location.locationId);

            return isAvailableGroup && isAvailableRoute;
        });

        if (!country || country === COUNTRIES_ANY || !countries) {
            return availableLocations;
        }

        const selectedCountryCodes = countries[country];

        return availableLocations.filter(location => selectedCountryCodes.find(code => code === location.locationId));
    }

    saveUserFilterValues(key: string = '_') {
        this.carsSharedService.saveUserFilterValues(this.userFilterStorageKey, this.extractUserFilterValues(), key);
    }

    clearUserFilterValues(key: string = '_') {
        this.carsSharedService.saveUserFilterValues(this.userFilterStorageKey, false, key);

        if (!this.defaultFilterValues) {
            document.location.reload();
        }

        // Prevent document load.
        this.storeState.filtersReady = false;

        const settings = this.defaultFilterValues;

        Object.assign(this.storeState.settings, {
            country: settings.country,
            pickUpCityCodes: settings.pickUpCityCodes,
            provider: settings.provider,
            dataSources: settings.dataSources,
            pos: settings.pos,
            lor: settings.lor,
            carClasses: settings.carClasses,
            transmission: settings.transmission,
            mileage: settings.mileage,
            paymentTerm: settings.paymentTerm,
            bml: settings.bml,
        });
        // Mark filters as ready and trigger data load
        this.storeState.filtersReady = true;
        this.parityService.resetLoading();
    }

    extractUserFilterValues() {
        const { settings } = this.storeState;
        return {
            country: settings.country,
            pickUpCityCodes: settings.pickUpCityCodes,
            provider: settings.provider,
            dataSources: settings.dataSources,
            pos: settings.pos,
            lor: settings.lor,
            carClasses: settings.carClasses,
            transmission: settings.transmission,
            mileage: settings.mileage,
            paymentTerm: settings.paymentTerm,
            bml: settings.bml,
        };
    }
}
