import { plainToClass } from 'class-transformer';
import { Inject, injectable } from 'inversify-props';
import ApiService, { ApiServiceS } from '../common/services/api.service';
import BranchesModel from './models/branches.model';
import CarsExcelReportModel from './models/cars-excel-report.model';
import CarOpenTelemetryService, { CarOpenTelemetryServiceS } from './modules/open-telemetry/car.open-telemetry.service';
import { LOGTYPE } from '../open-telemetry/constants';
import StoreFacade, { StoreFacadeS } from '../common/services/store-facade';
import CarsStore from './store/cars.store';

export const CarsExcelReportApiServiceS = Symbol.for('CarsExcelReportApiServiceS');

@injectable(CarsExcelReportApiServiceS as unknown as string)
export default class CarsExcelReportApiService {
    @Inject(StoreFacadeS) protected storeFacade!: StoreFacade;
    @Inject(ApiServiceS) private apiService!: ApiService;
    @Inject(CarOpenTelemetryServiceS) protected otelService!: CarOpenTelemetryService;

    readonly storeState: CarsStore = this.storeFacade.getState('CarsStore');

    private readonly baseUrl = '/car/excel-report';
    async generateExcelReport(chain: BranchesModel | null | undefined, report: Record<string, any>, isVans: boolean) {
        const { dataExportCurrency } = this.storeState.settings;

        const body = {
            details: {
                ...report,
            },
        };

        if (chain && chain.chainId) {
            body.details.required.chain_id = chain.chainId;
        }

        if (!body.details.optional) {
            body.details.optional = {};
        }

        if (dataExportCurrency) {
            body.details.optional.convert_to_currency = dataExportCurrency;
        }

        body.details.optional.is_vans = isVans;

        let spanName = 'car-rates';
        const prefix = LOGTYPE.DOWNLOAD;

        if (report.optional?.users_email) {
            spanName += '-email';
        }

        this.otelService.startSpan({ name: spanName, prefix });
        const { data } = await this.apiService.post(`${this.baseUrl}/generate-excel-report`, body);
        this.otelService.endSpan({ name: spanName, prefix }, { sendLogs: true });

        if (!data) {
            return null;
        }

        return plainToClass(CarsExcelReportModel, <CarsExcelReportModel>data, { excludeExtraneousValues: true });
    }

    async downloadExcelReport(reportId: string) {
        const result = await this.apiService.get(
            `${this.baseUrl}/download-excel-report/${reportId}`,
            {},
            { responseType: 'blob' },
        ).catch(() => null);

        if (!result || !result.data) {
            return null;
        }

        return result.data as Blob;
    }
}
