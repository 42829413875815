import { injectable } from 'inversify-props';
import { plainToClass } from 'class-transformer';
import CarsBaseAlertConfigs from './models/cars-base-alert-configs.model';
import CarsRateAlertApiService from './car.rate-alert-api.service';
import { ICarAlertConfig } from './modules/settings/interfaces/alert-interfaces';

export const CarsCompsetsAlertApiServiceS = Symbol.for('CarsCompsetsAlertApiServiceS');

@injectable(CarsCompsetsAlertApiServiceS as unknown as string)
export default class CarsCompsetsAlertApiService extends CarsRateAlertApiService {
    protected url = 'cr-insight/alert-compsets-config';

    async getAlertsConfiguration() : Promise<CarsBaseAlertConfigs | null | any> {
        const { data } = await this.apiService.get(`${this.url}?${this.queryParams}`);

        if (!data) {
            return null;
        }

        const carsDocument = plainToClass(CarsBaseAlertConfigs, data, { excludeExtraneousValues: true });
        return carsDocument;
    }

    async saveAlertConfiguration(configuration: ICarAlertConfig) {
        return this.apiService.post(this.url, configuration, {
            params: this.queryParams,
        });
    }

    private get queryParams(): URLSearchParams {
        return new URLSearchParams({ chainId: this.carsSharedService.currentChainId });
    }
}
