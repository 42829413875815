export default class LoadingModel {
    isInitialized: boolean = false;

    startDate: Date | null = null;
    finishDate: Date | null = null;

    constructor(isInitialized?: boolean) {
        if (isInitialized) {
            this.isInitialized = isInitialized;
        }
    }

    finishResolvers: (() => void)[] = [];

    resetHandler: () => void = () => {};

    reset() {
        this.startDate = null;
        this.finishDate = null;
        this.resetHandler();
    }

    start() {
        this.isInitialized = true;
        this.finishDate = null;
        this.startDate = new Date();
    }

    finish() {
        this.finishDate = new Date();
        this.resolveFinishPromises();
    }

    isLoading() {
        return Boolean(!this.isInitialized || (this.startDate && !this.finishDate));
    }

    onReset(cb: () => void) {
        this.resetHandler = cb;
    }

    whenLoadingFinished() {
        if (!this.isLoading()) return Promise.resolve();
        return new Promise<void>(resolve => {
            this.finishResolvers.push(resolve);
        });
    }

    private resolveFinishPromises() {
        this.finishResolvers.forEach(resolve => resolve());
        this.finishResolvers = [];
    }
}
