import { Inject, injectable } from 'inversify-props';
import * as _ from 'lodash';
import Stateable from '../common/interfaces/stateable.interface';
import StoreFacade, { StoreFacadeS } from '../common/services/store-facade';
import DocumentFiltersService, { DocumentFiltersServiceS } from '../document-filters/document-filters.service';
import { comparisonDays } from './models/cars-analysis.model';
import CarsStore from './store/cars.store';
import CarsSharedService, { CarsSharedServiceS } from './cars-shared.service';
import CarsService, { CarsServiceS } from './cars.service';
import CarsFiltersService, { CarsFiltersServiceS } from './cars-filters.service';

type Item = { value: string | number, name: string };

export const CarsAnalysisFiltersServiceS = Symbol.for('CarsAnalysisFiltersServiceS');
@injectable(CarsAnalysisFiltersServiceS as unknown as string)
export default class CarsAnalysisFiltersService implements Stateable {
    @Inject(CarsSharedServiceS) private carsSharedService!: CarsSharedService;
    @Inject(DocumentFiltersServiceS) protected documentFiltersService!: DocumentFiltersService;
    @Inject(StoreFacadeS) private storeFacade!: StoreFacade;
    @Inject(CarsServiceS) private carsService!: CarsService;
    @Inject(CarsFiltersServiceS) private carsFiltersService!: CarsFiltersService;

    readonly storeState: CarsStore = this.storeFacade.getState('CarsStore');

    comparisonFieldsDictionary = new Map([
        ['diffDays', 'Past Period'],
        ['carCategory', 'Car Category'],
    ]);

    private defaultFilterValues: { [key: string]: any } = {};
    private userFilterStorageKey = 'rates';

    constructor() {
        // Remember original chain defaults on first load.
        this.defaultFilterValues = JSON.parse(JSON.stringify({
            comparisonType: this.storeState.analysis.settings.comparisonFilter.key,
            comparisonValue: this.storeState.analysis.settings.comparisonFilter.values?.length ? this.storeState.analysis.settings.comparisonFilter.values[0].value : '',
        }));

        // Apply user defaults saved in localStorage
        const settings = this.carsSharedService.getUserFilterValues(this.userFilterStorageKey);
        if (settings) {
            this.storeState.analysis.settings.comparisonFilter.key = settings.comparisonType || this.defaultFilterValues.comparisonType;
            this.setComparisonValue(settings.comparisonValue || this.defaultFilterValues.comparisonValue);
        }

        // Mark filters as ready
        this.storeState.analysis.filtersReady = true;

        this.storeFacade.watch(() => [
            this.storeState.analysis.settings.comparisonFilter.key,
        ], () => { this.selectComparisonKey.bind(this); });

        this.storeFacade.watch(() => [
            this.carsService.storeState.settings.carClasses,
        ], () => {
            if (this.comparisonKey === 'carCategory') {
                if (this.carCategory.length === 0) {
                    this.comparisonValues = [];
                } else {
                    const currentNames = this.carCategory.map(item => item.name);
                    this.comparisonValues.forEach((compItem, index) => {
                        const findItem = currentNames.find(name => name === compItem.name);
                        if (!findItem) {
                            this.comparisonValues.splice(index, 1);
                        }
                    });
                    const clonedComparisonValues = _.cloneDeep(this.comparisonValues);
                    const newComparisonValues: any[] = [];
                    this.carCategory.forEach(item => {
                        const findIndex = clonedComparisonValues.findIndex(compItem => compItem.name === item.name);
                        if (findIndex >= 0) {
                            newComparisonValues.push({
                                name: item.name,
                                value: findIndex,
                            });
                        }
                    });
                    if (newComparisonValues.length) {
                        this.comparisonValues = newComparisonValues;
                    }
                }
            }
        });
    }

    private selectComparisonKey() {
        const comparisonValues: { [k: string]: () => any } = {
            diffDays: () => comparisonDays[0].value,
        };
        const value = comparisonValues[this.comparisonKey]();
        const name = this.getComparisonValueLabel(value);
        this.comparisonValues = [{ name, value }];
    }

    get mainFilterLabel() {
        const { filters } = this.carsSharedService;
        const { comparisonKey } = this;

        if (comparisonKey === 'diffDays') {
            return 'Today';
        }

        const value = filters[comparisonKey as keyof typeof filters] as string | number;

        return this.getComparisonValueLabel(value);
    }

    get comparisonKey() {
        return this.storeState.analysis.settings.comparisonFilter.key;
    }

    set comparisonKey(value: string) {
        this.storeState.analysis.settings.comparisonFilter.key = value;
        const [defaultComparisonValues] = comparisonDays;
        this.comparisonValues = [defaultComparisonValues];
    }

    get comparisonValues() {
        return this.storeState.analysis.settings.comparisonFilter.values ? this.storeState.analysis.settings.comparisonFilter.values : [];
    }

    set comparisonValues(values: Array<{ value: string | number, name: string }> | []) {
        this.storeState.analysis.settings.comparisonFilter.values = values;
    }

    get currentFilterItems(): Item[] {
        return this.filterItems[this.comparisonKey]();
    }

    get comparisonDayItems(): Item[] {
        return comparisonDays;
    }

    get carCategory(): Item[] {
        let items: Item[] = [];
        if (this.storeState.settings.carClasses) {
            items = this.storeState.settings.carClasses.map((item, index) => ({ name: item, value: index }));
        }
        return items;
    }

    get mainCompareTitle() {
        return 'Today';
    }

    get filterItems(): {[k: string]: (wholeList?: boolean) => Item[]} {
        const itemLists: { [k: string]: () => Item[] } = {
            diffDays: () => this.comparisonDayItems,
            carCategory: () => this.carCategory,
        };

        return itemLists;
    }

    get filterList(): Item[] {
        return Array
            .from(this.comparisonFieldsDictionary)
            .filter(([key]) => this.filterItems[key]().length)
            .map(([value, name]) => ({ value, name }));
    }

    getComparisonValueLabel(needle: string | number) {
        const { comparisonKey } = this;

        const valueTypes: { [k: string]: () => any } = {
            diffDays: () => this.getItemLabel(needle),
        };

        return valueTypes[comparisonKey]();
    }

    private getItemLabel(needle: number | string) {
        const item = this.currentFilterItems.find(x => x.value === needle);
        return item ? item.name : null;
    }

    setComparisonValue(value: string | number) {
        const findItem = this.currentFilterItems.find(element => element.value === value);
        const defaultValue: Item = this.currentFilterItems[0];
        this.comparisonValues = findItem ? [findItem] : [defaultValue];
    }

    setComparisonValues(values: any) {
        this.comparisonValues = values;
    }
}
